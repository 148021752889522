$background: #f8f8f8;

$FontColor-White: #fff;
$FontColor-Blue: #182b89;

.PageError {
  width: auto;
  height: 100vh;
  // background: $FontColor-Blue;
  background-image: linear-gradient(
      to bottom,
      rgba(24, 43, 136, 1),
      rgba(24, 43, 136, 0.5),
      rgba(24, 43, 136, 0.5)
    ),
    url("../../images/BG-21.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -80px 0 0;
}

.PageError > h1 {
  color: #fff;
  font-size: 40px;
  margin: 0 30px 0 0;
  padding-right: 30px;
  border-right: 1px solid #fff;
}

.PageError > div > p {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 40px;
}

.b-to-homepage-btn {
  font-size: 14px;
  font-weight: bold;
  color: $FontColor-Blue;
  background-color: #fff;
  text-decoration: none;
  padding: 12px 40px;
  border: 2px solid #fff;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.b-to-homepage-btn:hover {
  color: $FontColor-Blue;
  background-color: #fff;
  border: 2px solid #fff;
}

@media screen and (max-width: 576px) {
  .PageError {
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: 60% center;
  }

  .PageError > h1 {
    font-size: 36px;
    border: none;
    margin: 0 0 30px;
    padding: 0;
  }

  .PageError > div {
    width: 90%;
    text-align: center;
  }
}
