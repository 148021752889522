html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: bold;
}

p {
  margin: 0;
  font-weight: normal;
}

pre {
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
}

ul,
li {
  margin: 0;
  padding: 0;
}

input {
  -webkit-appearance: none;
}

.ant-message-notice-content {
  max-width: 800px;
}

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?n2y3ft");
  src:
    url("fonts/icomoon.eot?n2y3ft#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?n2y3ft") format("truetype"),
    url("fonts/icomoon.woff?n2y3ft") format("woff"),
    url("fonts/icomoon.svg?n2y3ft#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[data-icon]:before {
  font-family: "icomoon";
  content: attr(data-icon);
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
