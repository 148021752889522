$color-blue: #182b89;
$color-lightblue: #3ea6df;
$bg-grey: #d8d8d8;

$transition-2s: all 0.2s ease-in-out;

nav {
  // background-color: rgba(24, 43, 136, 1);
  background: linear-gradient(
    0deg,
    rgba(62, 167, 224, 0),
    rgba(24, 43, 136, 1)
  );
  width: 100%;
  height: 80px;
  max-height: 80px;
  top: 0;
  position: sticky !important;
  z-index: 2000 !important;
}

.nav-bgcolor {
  background: $color-blue;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.navbar-container {
  width: 95%;
  height: 80px;
  max-height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  width: 180px;

  & img {
    width: 160px;
  }
}

.nav-menu {
  width: 100%;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  & > .nav-item {
    color: #fff;
    height: 65px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    text-align: center;

    & > .nav-links {
      color: #fff;
      height: 65px;
      padding: 30px 30px 0;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      text-decoration: none;

      &.active {
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
      }
    }
  }
}

.nav-acc-dropdown {
  & svg {
    font-size: 24px;
  }

  & .btn-primary {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    outline: none;
    border: none !important;
    &:focus {
      box-shadow: none;
    }
  }

  & .dropdown-toggle::after {
    display: none;
  }

  & .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  & .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border: none;
  }

  & .nav-acc-dropdown-menu {
    transform: translate(0px, 53px) !important;
    padding: 0 20px;
    border: none;
    border-radius: 10px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);

    & a {
      color: $color-blue;
      font-size: 14px;
      padding: 12px 0;
      &:hover {
        background-color: #fff;
      }
    }
    & .dropdown-linediv {
      border-bottom: 1px solid #d8d8d8;
    }
  }
}

.offc-acc-dropdown-menu {
  width: 180px;
  height: fit-content;
  margin: 90px 40px 0 auto;
  padding: 10px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
  -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
  -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
  z-index: 5;

  & .offcanvas-body {
    padding: 0;
  }

  & button {
    background: #fff;
    color: $color-blue;
    font-size: 14px;
    width: 100%;
    margin: 0;
    padding: 8px 10px;
    outline: 0;
    border: none;
    border-radius: 5px;
    text-align: left;
    &:hover {
      background-color: #f5f5f5;
    }
  }
  & .dropdown-linediv {
    margin: 0 5px;
    border-bottom: 1px solid #e3e3e3;
  }
}

.nav-mob-menu {
  display: none;
}

// login modal
.login-modal {
  backdrop-filter: blur(10px);
  z-index: 2000;

  .modal-dialog {
    max-width: 400px;
  }

  & .modal-content {
    border-radius: 20px;
    border: none;
    box-shadow: none;
  }
}

.login-modal-header {
  border: none;
  padding-bottom: 0;

  & .modal-title {
    color: $color-blue;
    font-size: 20px;
    font-weight: bold;

    & p {
      color: #aeaeae;
      font-size: 12px;
      font-weight: normal;
    }
  }
}

.login-modal-body {
  padding: 20px 20px 30px;

  & .refpage-modalbody {
    margin: 20px 0 30px;
    & > p {
      color: $color-blue;
      font-size: 16px;
      font-weight: 500;
    }
  }

  & .login-form-username {
    margin: 0 0 10px;

    & > label {
      color: $color-blue;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    & > input {
      color: $color-blue;
      background-color: #fff;
      width: 100%;
      padding: 10px 14px;
      font-size: 12px;
      border: none;
      border-radius: 10px;
      outline: 0;
      box-shadow: 0 0 5px 0 $color-lightblue;
      &::placeholder {
        color: #989898;
      }
    }
  }

  & .login-form-ps {
    margin: 0 0 10px;

    & > label {
      color: $color-blue;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    & > input {
      color: $color-blue;
      background-color: #fff;
      width: 100%;
      padding: 10px 14px;
      font-size: 12px;
      border: none;
      border-radius: 10px;
      outline: 0;
      box-shadow: 0 0 5px 0 $color-lightblue;
      &::placeholder {
        color: #989898;
      }
    }
  }

  & .login-form-phone {
    margin: 0 0 10px;

    & > label {
      color: $color-blue;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    & > div {
      color: $color-blue;
      background-color: #fff;
      width: 100%;
      padding: 10px 14px;
      font-size: 12px;
      border: none;
      border-radius: 10px;
      line-height: initial;
      outline: 0;
      box-shadow: 0 0 5px 0 $color-lightblue;
      display: flex;
      align-items: center;
      & span {
        font-weight: 500;
      }
      & input {
        color: $color-blue;
        width: 100%;
        margin-left: 5px;
        font-size: 12px;
        outline: 0;
        border: none;
        &::placeholder {
          color: #989898;
        }
      }
      & .login-form-phone-input {
        font-family: "Poppins", sans-serif;
        padding: 0;
        border: none;
        color: $color-blue !important;
        text-transform: uppercase;
        line-height: initial;
        &::placeholder {
          color: #989898;
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          font-weight: normal;
          text-transform: capitalize;
        }
        &.ant-input-affix-wrapper-focused {
          border: none;
          box-shadow: none;
        }
        & .ant-input-show-count-suffix {
          font-family: "Poppins", sans-serif;
          font-size: 12px;
        }
      }
    }
  }

  & .login-form-etc {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px;

    & button {
      color: $color-blue;
      background-color: #fff;
      font-size: 12px;
      font-weight: 500;
      border: none;
      text-decoration: underline;
      transition: $transition-2s;

      &:hover {
        color: $color-lightblue;
      }
    }
  }

  & .login-form-email {
    margin: 0 0 10px;

    & > label {
      color: $color-blue;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    & > input {
      color: $color-blue;
      background-color: #fff;
      width: 100%;
      padding: 10px 14px;
      font-size: 12px;
      border: none;
      border-radius: 10px;
      outline: 0;
      box-shadow: 0 0 5px 0 $color-lightblue;
      &::placeholder {
        color: #989898;
      }
    }
  }

  & .login-alert-message {
    padding: 10px 16px;

    & .login-alert-text {
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 0 10px;
    }
  }

  & .fpass-input-info {
    color: $color-blue;
    font-size: 11px;
  }

  & .login-form-otp {
    margin-bottom: 10px;
    & > label {
      color: $color-blue;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    & > .login-form-otp-input {
      background-color: #fff;
      width: 100%;
      padding: 10px 14px;
      font-size: 12px;
      border: none;
      border-radius: 10px;
      outline: 0;
      box-shadow: 0 0 5px 0 $color-lightblue;

      & input {
        color: $color-blue !important;
        text-transform: uppercase;
        &::placeholder {
          color: #989898;
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          font-weight: normal;
          text-transform: capitalize;
        }
      }
    }

    &.hidden {
      display: none;
    }
  }

  & .register-verification {
    margin: 30px auto 20px;
    & .register-veri-header {
      margin: 0 auto 10px;
      & > .veri-h5 {
        color: $color-blue;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
      }
      & > p {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #aeaeae;
      }
    }
    & .register-veri-otp {
      & label {
        color: $color-blue;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      & div {
        background-color: #fff;
        width: 100%;
        height: 41px;
        margin: 0;
        padding: 0;
        font-size: 12px;
        border: none;
        border-radius: 10px;
        outline: 0;
        box-shadow: 0 0 5px 0 $color-lightblue;
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
        & .login-veri-otp-input {
          color: $color-blue !important;
          background-color: transparent;
          padding: 0 14px;
          border: none;
          border-radius: 10px;
          text-transform: uppercase;
          box-shadow: none;
          &::placeholder {
            color: #989898;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-weight: normal;
            text-transform: capitalize;
          }
        }
        & .login-veri-submit {
          color: $color-blue;
          background: none;
          height: fit-content;
          font-weight: 500;
          border: none;
          text-decoration: underline;
        }
        & .login-veri-submit-disabled {
          color: #aeaeae;
          background: none;
          height: fit-content;
          font-weight: 500;
          border: none;
          text-decoration: underline;
          cursor: default;
        }
      }
      & .resend-counter {
        color: $color-blue;
        font-size: 12px !important;
        margin-top: 5px;
        text-align: right;
      }
      &.hidden {
        display: none;
      }
    }
  }
}

.login-buttons {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  & button,
  & .login-submit-btn {
    color: #fff;
    background-color: $color-lightblue;
    padding: 14px 0;
    font-size: 12px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    transition: $transition-2s;
    outline: none;
    text-decoration: none;
    text-align: center;
    &:hover {
      background-color: #64b6e3;
    }
  }
  & .request-otp-btn.hidden {
    display: none;
  }
  & .verify-otp-btn.hidden {
    display: none;
  }

  & .register-disabled-btn {
    color: #fff;
    background-color: #bcbcbc;
    padding: 14px 0;
    font-size: 12px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    transition: $transition-2s;
    outline: none;
    text-align: center;
  }
}

.modal-backdrop {
  background-color: $color-blue;
}

@media screen and (min-width: 1901px) {
  .offc-acc-dropdown-menu {
    width: 180px;
    height: fit-content;
    margin: 90px 50px 0 auto;
  }
}

@media screen and (max-width: 1024px) {
  nav {
    // background-color: rgba(24, 43, 136, 1);
    background: rgba(24, 43, 136, 1);
  }

  .wrap-navbar {
    z-index: 100;
  }

  .logo-container img {
    width: 160px;
  }

  .nav-menu {
    display: none;
  }

  .tabl-menu {
    display: initial;
  }

  .nav-account {
    display: grid;
    grid-template-columns: 40px 40px;
    gap: 20px;
    align-items: center;
  }

  .nav-mob-menu {
    display: initial;
  }

  .mob-menu-item {
    & > .menu-icon-burger {
      color: #fff;
      font-size: 30px;
    }
  }

  /* modal */
  .login-modal {
    z-index: 3000000001 !important;
  }

  /* mobile menu offcanvas  */
  .mob-menu-offcanvas {
    display: initial;
    width: 95%;
    height: fit-content;
    z-index: 5;
    margin: 90px auto 0;
    border-radius: 10px;

    & .mob-menu-body {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0;
      padding: 0;
      border-radius: 10px;

      & .offcanvas-link {
        color: $color-blue !important;
        text-decoration: none;
        font-size: 18px;
        padding: 20px 30px;
      }

      & .offcanvas-link.active {
        color: #fff !important;
        background-color: $color-blue;
        font-size: 18px;
        border-radius: 0;
      }
    }
  }

  .offcanvas-backdrop {
    background-color: rgba(24, 43, 137, 0.5);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2 !important;
    margin: 80px 0 0;
    backdrop-filter: blur(10px);
  }
  .offcanvas-backdrop.show,
  .offcanvas-backdrop.fade {
    opacity: 1;
    z-index: 0;
  }
}

@media screen and (max-width: 576px) {
  .logo-container img {
    width: 120px;
  }

  /* mobile menu offcanvas  */
  .mob-menu-offcanvas {
    width: 95%;
    height: fit-content;
    z-index: 5;
    margin: 90px auto 0;
    border-radius: 10px;
    border: none;

    & .mob-menu-body {
      display: grid;
      grid-template-columns: 1fr;

      & .offcanvas-link {
        font-size: 14px;
        padding: 14px 20px;
      }

      & .offcanvas-link.active {
        font-size: 14px;
      }
    }
  }

  // login dropdown
  .offc-acc-dropdown-menu {
    width: 95%;
    height: fit-content;
    margin: 90px auto 0;
    padding: 0;
    border: none;
    border-radius: 10px;
    z-index: 5;

    & .offcanvas-body {
      padding: 0;
    }

    & button {
      background: #fff;
      color: $color-blue;
      width: 100%;
      margin: 0;
      padding: 16px 20px;
      border-radius: 10px;
      &:hover {
        background-color: #fff;
      }
    }
    & .dropdown-linediv {
      margin: 0 15px;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  // login modals
  .login-modal .modal-dialog {
    max-width: 500px;
  }

  .login-modal-body {
    padding: 20px;

    & .login-form-username {
      & > label {
        font-size: 12px;
      }
    }

    & .login-form-email {
      & > label {
        font-size: 12px;
      }
    }

    & .login-form-ps {
      & > label {
        font-size: 12px;
      }
    }

    & .login-form-phone,
    & .login-form-otp {
      & > label {
        font-size: 12px;
      }
    }

    & .login-form-etc {
      margin: 0 0 20px;
    }

    & .register-verification {
      & .register-veri-otp {
        & label {
          font-size: 12px;
        }
        & div {
          background-color: #fff;
          width: 100%;
          height: 41px;
          & .login-veri-otp-input {
            height: 41px;
            &::placeholder {
              color: #989898;
              font-family: "Poppins", sans-serif;
              font-size: 12px;
              font-weight: normal;
              text-transform: capitalize;
            }
          }
          & .login-veri-submit {
            font-size: 12px;
          }
        }
      }
    }
  }

  .login-buttons {
    width: 100%;
    gap: 10px;
  }
}

@media screen and (max-width: 376px) {
  .login-modal .modal-dialog {
    max-width: 95%;
  }
}
