$color-blue: #182b89;
$color-lightblue: #3ea6df;

$transition-2s: all 0.2s ease-in-out;

// hero
.wrap-hero {
  width: 100%;
  height: 100vh;
  margin: -80px auto 0;
  // background: linear-gradient(90deg, rgba(0, 0, 0, 0.75),rgba(0, 0, 0, 0.55)),  url('../images/BG-02.png');
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgb(24, 43, 136)
    ),
    url("../images/BG-02.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.hero-container {
  width: 60%;
  background-color: rgba(238, 238, 238, 0.15);
  border-radius: 0 300px 300px 0;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: grid;
  grid-template-columns: 1fr;
  padding: 90px;
  align-items: center;

  & > h1 {
    width: 80%;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    & > span {
      font-size: 50px;
    }
  }

  & > .hero-linediv {
    width: 80%;
    border-bottom: 3px solid #fff;
    margin: 10px 0;
  }

  & > p {
    width: 80%;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
  }

  & > p.hero-text-1 {
    margin-bottom: 30px;
  }
}

// landing
.wrap-landing {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

// ld-container-1
.ld-container-1 {
  width: 100%;
  height: auto;
  padding: 120px 0;
}

.ld-1-content {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 50px 1fr 50px 1fr;
  grid-auto-rows: 1fr;
  gap: 35px;
}

.ld-1-content > .ld-1-item-1 {
  width: 100%;
  height: auto;

  & > .ld-1-item-1-header {
    background-color: #fff;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0 0 15px 0 rgba(59, 158, 217, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;

    & > h2 {
      color: $color-blue;
      font-size: 48px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      text-align: center;
      text-transform: uppercase;
      & > span {
        font-size: 24px;
      }
    }
  }

  & > .ld-1-item-1-body {
    background-color: #fff;
    width: 100%;
    height: 280px;
    padding: 120px 30px 0;
    margin: -90px 0 0;
    border-radius: 30px;
    box-shadow: 4px 4px 15px 0 rgba(61, 165, 223, 0.3);
    z-index: 1;
    text-align: center;

    & > h3 {
      color: $color-blue;
      font-size: 20px;
      font-weight: 500;
      margin: 0 0 12px;
    }

    & > p {
      color: $color-blue;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.71;
    }
  }
}

.ld-1-content > .ld-1-item-2 {
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 100%;
  }
}

// ld-container-2
.ld-container-2 {
  width: 100%;
  height: 110vh;
  margin: 30px 0 0;
  padding: 0;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    ),
    url("../images/BG-03-01.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ld-2-content {
  width: 60%;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 500px 0 0 500px;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  & > .ld-2-item-1 {
    width: 70%;
    margin: 90px auto;

    & > h2 {
      color: #fff;
      width: 50%;
      font-size: 45px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    & > h3 {
      color: #fff;
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    & > p {
      color: #fff;
      font-size: 14px;
      font-weight: normal;
    }
    & > .ld-2-content-text-1 {
      margin-bottom: 20px;
    }
  }
}

// ld-container-3
.ld-container-3 {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 120px 0 0;
}

.ld-3-content {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;

  & > .ld-3-item-1 {
    background-color: #fff;
    padding: 60px 0;
    border-radius: 240px;
    box-shadow: 4px 4px 15px 0 rgba(61, 165, 223, 0.3);
    text-align: center;
    & > img {
      width: 90px;
      margin: 0 auto 30px;
    }
    & > p {
      color: $color-blue;
      font-size: 20px;
      font-weight: bold;
      padding: 0 40px;
      text-transform: capitalize;
    }
  }
}

// ld-container-4
.ld-container-4 {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 120px 0 90px;
}

.ld-4-content {
  width: 90%;
  height: auto;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;

  & > .ld-4-item-1 {
    width: 100%;

    & > img {
      width: 90%;
    }
  }

  & > .ld-4-item-2 {
    width: 100%;
    height: auto;

    & > .ld-4-item-2-1 {
      border-left: 4px solid $color-blue;
      padding: 20px 0 20px 30px;
      margin: 0 0 60px;
    }

    & > .ld-4-item-2-2 {
      border-left: 4px solid $color-blue;
      padding: 20px 0 20px 30px;
    }

    & h2 {
      color: $color-blue;
      font-size: 24px;
      font-weight: 600;
      margin: 0 0 10px;
      text-transform: uppercase;
    }
    & p {
      color: $color-lightblue;
      font-size: 18px;
      font-weight: normal;
    }
  }
}

// ld-container-5
.ld-container-5 {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 90px 0;
}

.ld-cont-5-header {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto 30px;
  padding: 0 0 0 20px;
  border-left: 20px solid $color-blue;

  & > h2 {
    color: $color-blue;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
}

.ld-5-content {
  width: 100%;
  height: 100vh;
  background-image: url("../images/BG-08.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  display: flex;
  align-items: center;

  & > .ld-5-item-1 {
    width: 55%;
    padding: 120px 0;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 0 500px 500px 0;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);

    & > h3 {
      width: 70%;
      margin: 0 auto 20px;
      color: #fff;
      font-size: 36px;
      font-weight: bold;
      display: grid;
      grid-template-columns: 1fr;
      & > span {
        font-size: 50px;
      }
    }
    & > p {
      width: 70%;
      margin: 0 auto;
      color: #fff;
      font-size: 30px;
      font-weight: 500;
    }
  }
}

// ld-container-6
.ld-container-6 {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0 0 120px;
}

.ld-cont-6-header {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto 30px;
  padding: 0 0 0 20px;
  border-left: 20px solid $color-blue;

  & > h2 {
    color: $color-blue;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
}

.ld-6-content {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto;
}

.ld-6-item-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  & > .ld-6-item-1-1 {
    text-align: center;
    & > img {
      width: 80%;
    }

    & > h4 {
      color: $color-blue;
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

// ld-container-7
.ld-container-7 {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 90px 0;
}

.ld-cont-7-header {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto 30px;
  padding: 0 0 0 20px;
  border-left: 20px solid $color-blue;

  & > h2 {
    color: $color-blue;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
}

.ld-7-content {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto;
}

.ld-7-item-1 {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  gap: 50px;

  & > .ld-7-item-1-1 {
    padding: 70px 50px;
    border-radius: 500px;
    box-shadow: 2px 2px 10px 0 rgba(61, 165, 223, 0.3);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > img {
      width: 80px;
      height: 80px;
      margin-bottom: 30px;
    }

    & > h4 {
      color: $color-blue;
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  & > .ld-7-item-1-2 {
    text-align: center;
    & > img {
      width: 90%;
    }
  }
}

// ld-container-8
.ld-container-8 {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.ld-8-content {
  width: 100%;
  height: 100vh;
  background-image: url("../images/BG-15.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ld-8-cont-header {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto 50px;
  padding: 0 0 0 20px;
  border-left: 20px solid #fff;

  & > h2 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
}

.ld-8-item-1 {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto;

  & .slick-list {
    width: 95%;
    margin: 0 auto;
  }

  & .ld-8-item-cards-1 {
    background: rgba(255, 255, 255, 0.35);
    width: 350px !important;
    height: 220px;
    margin: 0 auto;
    display: flex !important;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 30px;
    // cursor: pointer;

    & > img {
      width: 70%;
      margin: 0 auto;
    }
  }
}

.slick-prev,
.slick-next {
  width: 32px !important;
  height: 32px !important;
}
.cont-carousel-1 .slick-next:before {
  color: $color-blue;
  font-size: 30px;
}
.cont-carousel-1 .slick-prev:before {
  color: $color-blue;
  font-size: 30px;
}

// transaction message modal
.transaction-message-mod {
  & .modal-dialog {
    justify-content: center;
  }

  & .modal-content {
    width: 90%;
    max-width: 400px;
    border: none;
    border-radius: 15px;
  }

  & .modal-header {
    border: none;
    & .modal-title {
      color: $color-blue;
      font-size: 20px;
      font-weight: bold;
    }
    & .btn-close {
      &:focus {
        box-shadow: none;
      }
    }
  }

  & .modal-body {
    padding: 0 16px 30px;
    text-align: center;

    & .transaction-mod-icon {
      font-size: 80px;
      padding: 0;
      &.suc-transac {
        color: #0ea700;
      }
      &.failed-transac {
        color: #fc3737;
      }
    }
    & > h3 {
      color: $color-blue;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    & > p {
      color: #000;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 25px;
    }
    & > button {
      color: #fff;
      background-color: $color-lightblue;
      width: 80%;
      padding: 12px 0;
      font-size: 13px;
      font-weight: bold;
      outline: 0;
      border: none;
      border-radius: 10px;
    }
  }
}

/* slide up animation */
@media screen and (min-width: 1200px) {
  .reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
  }

  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
}

/* responsive */
@media screen and (min-width: 1901px) {
  .hero-container {
    padding: 140px 90px;
  }

  .ld-1-content > .ld-1-item-1 > .ld-1-item-1-body {
    height: 260px;
  }

  .ld-2-content {
    width: 55%;
  }

  .ld-3-content > .ld-3-item-1 {
    border-radius: 50%;
    padding: 70px 40px;
  }

  .ld-5-content > .ld-5-item-1 {
    padding: 160px 0;
  }

  .ld-7-item-1 > .ld-7-item-1-1 {
    padding: 80px 50px;
  }
}

@media screen and (max-width: 1200px) {
  .wrap-hero {
    & > .hero-container {
      width: 80%;
      padding: 90px 40px;

      & > h1 {
        width: 90%;
        font-size: 32px;
        & > span {
          font-size: 40px;
        }
      }

      & > .hero-linediv {
        margin: 20px 0;
      }

      & > p {
        font-size: 20px;
      }
    }
  }

  .ld-1-content {
    width: 90%;
    gap: 25px;
  }
}

@media screen and (max-width: 1024px) {
  .ld-1-content {
    width: 90%;
    grid-template-columns: 1fr 35px 1fr 35px 1fr;
    gap: 20px;

    & > .ld-1-item-1 {
      & > .ld-1-item-1-header {
        width: 120px;
        height: 120px;
        & > h2 {
          font-size: 40px;
        }
      }
      & > .ld-1-item-1-body {
        height: auto;
        margin: -60px 0 0;
        padding: 80px 30px 20px;
        & > h3 {
          font-size: 20px;
        }
      }
    }
  }

  .ld-container-2 {
    height: 55vh;
  }
  .ld-2-content {
    width: 90%;

    & > .ld-2-item-1 {
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
      & > h2 {
        width: 100%;
        font-size: 30px;
        margin-bottom: 10px;
      }
      & > h3 {
        width: 100%;
        font-size: 20px;
      }

      & > p {
        font-size: 14px;
      }
    }
  }

  .ld-container-3 {
    padding: 60px 0 0;
  }
  .ld-3-content {
    grid-template-columns: 1fr 1fr;
  }

  .ld-cont-5-header {
    width: 90%;
  }
  .ld-5-content {
    height: 60vh;

    & > .ld-5-item-1 {
      width: 70%;
    }
  }
  .ld-container-5 {
    padding: 30px 0;
  }

  .ld-cont-6-header {
    width: 90%;
  }
  .ld-6-content {
    width: 90%;
  }
  .ld-6-item-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    & > .ld-6-item-1-1 {
      text-align: center;
      & > img {
        width: 100%;
      }
    }
  }

  .ld-cont-7-header {
    width: 90%;
  }
  .ld-7-content {
    width: 90%;
  }
  .ld-7-item-1 {
    gap: 30px;
    & > .ld-7-item-1-1 {
      padding: 50px 30px;
      & > img {
        width: 60px;
        height: 60px;
      }
      & > h4 {
        font-size: 20px;
      }
    }
  }

  .slick-slide {
    width: 100%;
  }

  .ld-8-cont-header {
    width: 90%;
  }
  .ld-8-item-1 {
    width: 90%;
  }
  .ld-8-item-1 .ld-8-item-cards-1 {
    width: 100% !important;
    padding: 0 10px;
  }

  .ld-8-content {
    height: 50vh;
  }

  // transaction-message modal
  .transaction-message-mod {
    & .modal-dialog {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 576px) {
  .wrap-hero {
    height: 550px;
    margin: 0 auto;
    & > .hero-container {
      width: 85%;
      padding: 70px 30px;
      & > h1 {
        font-size: 20px;
        & > span {
          font-size: 30px;
        }
      }

      & > .hero-linediv {
        margin: 15px 0;
      }

      & > p {
        font-size: 14px;
      }
      & > p.hero-text-1 {
        margin-bottom: 12px;
      }
    }
  }

  .ld-container-1 {
    padding: 60px 0;
  }

  .ld-1-content {
    width: 90%;
    grid-template-columns: 1fr;
    gap: 20px;

    div:nth-child(5) {
      margin-top: 25px;
    }
  }

  .ld-1-content > .ld-1-item-1 {
    height: auto;

    & > .ld-1-item-1-header {
      width: 100px;
      height: 100px;
      & > h2 {
        font-size: 32px;
        & > span {
          font-size: 20px;
        }
      }
    }

    & > .ld-1-item-1-body {
      padding: 80px 30px 30px;
      & > h3 {
        font-size: 16px;
        font-weight: 500;
      }
      & > p {
        font-size: 13px;
      }
    }
  }

  .ld-1-content > .ld-1-item-2 {
    display: none;
  }

  .ld-container-2 {
    height: 450px;
  }
  .ld-2-content {
    padding: 40px 0 40px 60px;
    background-color: rgba(255, 255, 255, 0.1);

    & > .ld-2-item-1 {
      width: 90%;
      margin: 0 0 0 auto;
      & > h2 {
        font-size: 16px;
      }
      & > h3 {
        font-size: 13px;
      }

      & > p {
        font-size: 9px;
      }
    }
  }

  .ld-3-content {
    width: 90%;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    & > .ld-3-item-1 {
      width: 100%;
      height: auto;
      padding: 40px 10px;

      & > img {
        width: 60px;
      }
      & > p {
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }

  .ld-container-4 {
    padding: 60px 0;
  }
  .ld-4-content {
    grid-template-columns: 1fr;

    & > .ld-4-item-2 {
      & > .ld-4-item-2-1 {
        margin: 0 0 30px;
      }
      & h2 {
        font-size: 20px;
      }
      & p {
        font-size: 14px;
      }
    }
  }

  .ld-cont-5-header {
    width: 90%;
    border-left: 15px solid $color-blue;
    & > h2 {
      font-size: 20px;
    }
  }
  .ld-container-5 {
    padding: 60px 0;
  }
  .ld-5-content {
    height: 550px;

    & > .ld-5-item-1 {
      width: 85%;
      padding: 90px 0;

      & > h3 {
        font-size: 24px;

        & span {
          font-size: 30px;
        }
      }

      & > p {
        font-size: 16px;
      }
    }
  }

  .ld-container-6 {
    padding: 0 0 60px;
  }
  .ld-cont-6-header {
    width: 90%;
    border-left: 15px solid $color-blue;
    & > h2 {
      font-size: 20px;
    }
  }
  .ld-6-item-1 {
    grid-template-columns: 1fr;
    gap: 20px;
    & > .ld-6-item-1-1 {
      & > h4 {
        font-size: 20px;
      }
    }
  }

  .ld-container-7 {
    padding: 60px 0 90px;
  }
  .ld-cont-7-header {
    width: 90%;
    border-left: 15px solid $color-blue;
    & > h2 {
      font-size: 20px;
    }
  }
  .ld-7-item-1 {
    grid-template-columns: 1fr;
    gap: 30px;

    & > .ld-7-item-1-1 {
      padding: 30px 0;

      & > h4 {
        font-size: 20px;
      }

      & > img {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
      }
    }

    & > .ld-7-item-1-2 > img {
      width: 100%;
    }
  }

  .ld-8-cont-header {
    width: 90%;
    border-left: 15px solid #fff;
    & > h2 {
      font-size: 20px;
    }
  }

  .ld-8-item-1 {
    width: 85%;

    & .slick-slide {
      & > div {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  .ld-8-item-1 .ld-8-item-cards-1 {
    height: 190px;
    & img {
      width: 65%;
    }
  }

  // transaction-message modal
  .transaction-message-mod {
    & .modal-header {
      & .modal-title {
        font-size: 18px;
      }
    }

    & .modal-body {
      & > h3 {
        font-size: 18px;
      }
      & > p {
        font-size: 12px;
      }
    }
  }
}
