$color-blue: #182b89;
$color-lightblue: #3ea6df;

$transition-2s: all 0.2s ease-in-out;

.modal-backdrop {
  background: rgba(24, 43, 137, 0.5);
  backdrop-filter: blur(10px);
}
.modal-backdrop.show {
  opacity: 1;
}
// hero
.wrap-sgcars-hero {
  width: 100%;
  height: 100vh;
  margin: -80px auto 0;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(24, 43, 136, 0.1),
      rgba(24, 43, 136, 0.3),
      rgba(24, 43, 136, 0.5),
      rgba(24, 43, 136, 0.6),
      rgba(24, 43, 136, 0.7)
    ),
    url("../../images/BG-38.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.sgcars-header {
  width: 80%;
  max-width: 1600px;
  margin: 0 auto 60px;
  padding: 0 0 0 20px;
  border-left: 20px solid $color-blue;

  & .sgcars-breadcrumbs {
    display: flex;

    & a {
      color: #aeaeae;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0.5px;
      text-decoration: none;
      text-transform: capitalize;
      transition: $transition-2s;
      &:hover {
        color: $color-blue;
      }
    }

    & .sgcars-breadcrumbs-divider {
      color: #aeaeae;
      margin: 0 10px;
      font-size: 22px;
    }

    & h2 {
      color: $color-blue;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0.5px;
      margin: 0;
      line-height: initial;
      text-transform: capitalize;
    }
  }
}

.sgcars-container-1 {
  width: 100%;
}

.sgcars-item-banner {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 15px;
  background-color: #fff;
  width: 100%;
  text-align: center;
  border-radius: 15px;

  & > img {
    width: auto;
    max-height: 450px;
    border-radius: 15px;
  }
}
// car image thumbnails
.sgcars-item-carousel {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 15px;

  & div {
    border-radius: 15px;
  }

  & .sgcars-item-carousel-img {
    display: flex;
    max-width: 85%;
    max-height: 450px;
    border-radius: 15px;
    margin: 0 auto;
    object-fit: cover;
  }

  & .carousel-indicators {
    & button {
      width: 5px !important;
      height: 4px !important;
      border: none !important;
      border-radius: 10px;
      opacity: 0.6;
    }
    & .active {
      width: 24px !important;
      background-color: $color-lightblue;
      opacity: 1;
    }
  }

  & .carousel-control-next {
    right: -30px;
  }
  & .carousel-control-next-icon {
    background-image: url("../../images/right-arrow-03.png") !important;
  }
  & .carousel-control-prev {
    left: -30px;
  }
  & .carousel-control-prev-icon {
    background-image: url("../../images/left-arrow-03.png") !important;
  }
}

.sgcars-item-header {
  margin: 0 0 30px;
  & > p {
    color: $color-blue;
    font-size: 18px;
    font-weight: normal;
    margin: 0 0 10px;
  }

  & > h4 {
    color: $color-blue;
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 10px;
  }

  & > .cars-card-item-country {
    color: #989898;
    font-size: 18px;
    font-weight: normal;

    & > .cars-card-item-country-icon {
      color: #989898;
      font-size: 25px;
      margin-right: 8px;

      & path {
        stroke: #989898;
      }
    }
  }
}

.sgcars-item-attribute {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 15px;

  & .sgcars-attri_item {
    padding: 20px 10px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);

    & > img {
      width: 40px;
      height: 40px;
      margin: 0 auto 10px;
    }

    & > p {
      color: $color-blue;
      font-size: 12px;
      font-weight: bold;
      margin: 0 auto 5px;
    }

    & > h5 {
      color: #989898;
      font-size: 11px;
      font-weight: normal;
    }
  }
}

.sgcars-item-div {
  margin: 30px 0;
}

.sgcars-item-dutylist {
  margin: 0 0 30px;
  & > h5 {
    color: $color-blue;
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 10px;
  }

  & .sgcars-item-dutylist-table {
    color: $color-blue;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    & thead {
      font-size: 14px;
      & tr {
        border: none !important;
      }
      & th {
        border-bottom: 1px solid #d9d9d9 !important;
        padding: 14px;
      }
    }
    & .sgcars-item-dutylist-border {
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
    }

    & td {
      font-size: 14px;
      font-weight: normal;
    }
    & td.hidden {
      display: none;
    }

    & tr:last-child td {
      border-bottom: none !important;
    }
  }
}

.sgcars-item-description {
  & > h5 {
    color: $color-blue;
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 10px;
  }

  & > p {
    color: $color-blue;
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 10px;
  }
}

.sgcars-loan-calculator {
  & .alert {
    & button {
      width: 12px;
      height: 12px;
      background-size: 30%;
      &:focus {
        box-shadow: none;
      }
    }
    & p {
      font-size: 14px;
    }
  }

  & input {
    width: 100%;
    color: $color-blue;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 0 10px;
    border: none;
    border-radius: 10px;
    outline: 0;
  }
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    /* Chrome, Safari, Edge, Opera */
    -webkit-appearance: none;
    margin: 0;
  }
  & input[type="number"] {
    /* Firefox */
    -moz-appearance: textfield;
  }
  & input::-webkit-input-placeholder {
    /* Edge */
    color: #989898;
    font-size: 14px;
    font-weight: normal;
  }
  & input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #989898;
    font-size: 14px;
    font-weight: normal;
  }
  & input::placeholder {
    color: #989898;
    font-size: 14px;
    font-weight: normal;
  }
}

.sgcars-loan-sec-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin: 0 0 30px;
}

.sgcars-loan-item-1 {
  & p {
    color: $color-blue;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  & > div {
    color: $color-blue;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
  }

  & .ant-input-number {
    color: $color-blue;
    width: 100%;
    height: auto !important;
    border: none;
    line-height: normal;

    &-focused {
      border: none;
      box-shadow: none;
    }
    & input {
      color: $color-blue;
      width: 100%;
      height: auto !important;
    }
    & input::-webkit-input-placeholder {
      /* Edge */
      color: #989898;
      font-size: 14px;
      font-weight: normal;
    }
    & input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #989898;
      font-size: 14px;
      font-weight: normal;
    }
    & input::placeholder {
      color: #989898;
      font-size: 14px;
      font-weight: normal;
    }
    & .ant-input-number-handler-wrap {
      display: none;
    }
  }

  & .sgcars-loan-item-1-1 {
    justify-content: flex-start;
    & span {
      // color: $color-lightblue;
      margin-left: 10px;
    }
  }
  & .sgcars-loan-item-1-2 {
    & input {
      text-align: right;
      color: $color-lightblue;
    }
    & span {
      // color: $color-lightblue;
      margin-right: 5px;
    }
  }
  & .sgcars-loan-item-1-3 {
    & p {
      margin: 0 0 0 10px;
      font-size: 14px;
    }
  }
}

.sgcars-loan-sec-2 {
  display: grid;
  grid-template-columns: 1fr;
  // gap: 30px;

  & > h4 {
    color: $color-lightblue;
    font-size: 12px;
    font-weight: bold;
  }
}

.sgcars-loan-item-2 {
  & p {
    color: $color-blue;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  & input {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
  }
}

.sgcars-calculate-btn {
  color: #fff;
  background-color: $color-lightblue;
  width: 100%;
  padding: 12px 0;
  margin: 30px 0 0;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  outline: 0;
  border: none;
  border-radius: 15px;
  transition: $transition-2s;
  &:hover {
    background-color: #64b6e3;
  }
}

.canvas_chart {
  cursor: pointer !important;
}

.sgcars-deposit-btn {
  color: #fff;
  background-color: $color-lightblue;
  width: 100%;
  padding: 12px 0;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  outline: 0;
  border: none;
  border-radius: 10px;
  transition: $transition-2s;
  &:hover {
    background-color: #64b6e3;
  }
}

// calculate result
.sgcars-calculate-modal {
  & .modal-dialog {
    width: 90%;
    max-width: 700px;
  }

  & .modal-content {
    border: none;
    border-radius: 15px;
  }

  & .modal-header {
    border: none;
    padding: 16px 30px;
    & .modal-title {
      color: $color-blue;
      font-size: 20px;
      font-weight: bold;
    }
    & .btn-close {
      &:focus {
        box-shadow: none;
      }
    }
  }

  & .modal-body {
    padding: 0 30px;
    border: none;
  }

  & .sgcars-modalbody-1 {
    & .canvas_chart {
      width: 180px;
      height: 180px;
      margin-block: 0;
    }
  }

  & .sgcars-modalbody-2 {
    margin: 0 0 12px;

    & .sgcars-modalbody-2-1 {
      color: $color-blue;
      font-size: 13px;
      font-weight: 500;
      margin: 0 0 5px;
    }
    & .sgcars-modalbody-2-2 {
      color: #fff;
      background-color: $color-blue;
      padding: 8px 15px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 10px;
      box-shadow: 0 0 5px 0 $color-lightblue;
    }
  }
  & .sgcars-modalbody-3 {
    margin: 0 0 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    & > .sgcars-modalbody-3-item .sgcars-modalbody-3-1 {
      color: $color-blue;
      font-size: 13px;
      font-weight: 500;
      margin: 0 0 5px;
    }
    & > .sgcars-modalbody-3-item .sgcars-modalbody-3-2 {
      color: #fff;
      background-color: $color-blue;
      padding: 8px 15px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 10px;
      box-shadow: 0 0 5px 0 $color-lightblue;
    }
  }

  & .modal-footer {
    padding: 10px 30px 30px;
    border: none;

    & a {
      width: 100%;
      margin: 0;
    }

    & .sgcars-deposit-btn {
      color: #fff;
      background-color: $color-lightblue;
      width: 100%;
      padding: 12px 0;
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      outline: 0;
      border: none;
      border-radius: 10px;
      transition: $transition-2s;
      &:hover {
        background-color: #64b6e3;
      }
    }
  }
}

.sgcars-accord-etc {
  .sgcars-accord-item {
    border: none;
    margin: 10px 0;

    & .accordion-header {
      & .accordion-button {
        color: $color-blue;
        background-color: #fff;
        width: 100%;
        padding: 10px 0;
        font-size: 12px;
        font-weight: 600;
        &:focus {
          box-shadow: none;
        }
        &::after {
          width: 12px;
          height: 12px;
          // display: none;
          margin: 0 0 0 12px;
          color: $color-blue;
          background: url("../../images/chevron-down-01.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
        &:not(.collapsed)::after {
          background: url("../../images/chevron-down-02.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }

    & .accordion-body {
      padding: 10px 10px 20px;
      // padding: 10px 0 20px;
      border-bottom: 1px solid #d9d9d9;
      & p {
        color: $color-blue;
        font-size: 12px;
        font-weight: 500;

        & span {
          margin: 0 0 0 10px;
        }
      }
    }

    & .sgcars-accord-roadtax {
      & .fin-tab-pane-2-saloon-type-1 > h5 {
        font-size: 14px;
      }
      & .fin-tab-pane-2-saloon-type-1 > p {
        font-size: 12px;
      }
      & .fin-cal-option .dropdown-item {
        font-size: 12px;
      }
    }

    // content
    & .fin-tab-pane-2-header {
      display: none;
    }
    & .fin-tab-pane-2-content > .fin-tab-pane-2-input {
      margin: 0 auto 20px;
      & h4 {
        font-size: 12px;
        font-weight: 500;
      }
      & > .fin-tab-pane-payment {
        font-size: 28px;
        padding: 16px 20px;
      }
    }
    & .fin-tab-pane-2-content > .fin-tab-pane-calculate-btn {
      font-size: 14px;
    }
    & .fin-cal-option .btn {
      font-size: 12px;
    }
    & .fin-cal-option .dropdown-item {
      font-size: 12px;
    }
  }
  & .accordion-button:not(.collapsed) {
    box-shadow: none;
  }
}

// make payment modal
.sgcars-calculate-modal.mpayment-modal {
  & .modal-dialog {
    width: 90%;
    max-width: 1000px;
    margin-top: 100px;
  }

  & .pcars-container {
    width: 95%;
    margin-bottom: 30px;
  }

  & .pcars-img-holder {
    height: 350px;
  }

  & .sgcars-deposit-btn {
    width: 100%;
  }

  & .pcars-pricing-item-2 > p {
    font-size: 24px;
  }
  & .pcars-payment-details {
    margin: 0;
  }

  & .pcars-price-summary {
    & .pcars-summary-item-2 > h5 {
      font-size: 18px;
    }
    & .pcars-summary-item-2 .pcars-summary-item-2-wrap {
      margin-top: 10px;
      & > p {
        font-size: 16px;
      }
    }
  }
  & .pcars-payment-method > h5,
  & .pcars-payment-details > h5 {
    font-size: 18px;
  }
  &
    .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-1
    .pcars-payment-details-item-1-1,
  &
    .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-2
    .pcars-payment-details-item-2-1 {
    font-size: 16px;
  }
  & .modal-footer {
    padding: 0 0 30px;
    width: 85%;
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 20px;
    margin: 0 auto;

    & button:nth-child(2) {
      background-color: rgb(216, 75, 75);
    }
  }
}

.ant-message {
  z-index: 3000000002 !important;
  margin-top: 20px;

  & svg {
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 1901px) {
  .sgcars-header {
    width: 80%;
    max-width: 1600px;
    margin: 0 auto 60px;
  }

  .sgcars-item-attribute {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;

    & .sgcars-attri_item {
      & > h5 {
        color: #989898;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  // .sgcars-calculate-modal {
  //     z-index: 3001 !important;
  // }
}

@media screen and (min-width: 1201px) and (max-width: 1900px) {
  .sgcars-calculate-modal {
    z-index: 3001 !important;
  }
  .sgcars-calculate-modal.mpayment-modal {
    z-index: 1999 !important;
  }
}

@media screen and (max-width: 1200px) {
  .wrap-sgcars-hero {
    height: 800px;
  }

  .cars-container-1 {
    width: 90%;
    grid-template-columns: 280px 1fr;
    gap: 40px;
  }

  .sgcars-header {
    width: 90%;
    margin: 0 auto 30px;

    & .sgcars-breadcrumbs {
      flex-wrap: wrap;

      & a {
        font-size: 18px;
      }
      & h2 {
        width: fit-content;
        font-size: 18px;
      }
      & .sgcars-breadcrumbs-divider {
        font-size: 18px;
      }
    }
  }

  .sgcars-item-header {
    & > p {
      font-size: 14px;
    }
    & > .cars-card-item-country {
      font-size: 14px;
      & > .cars-card-item-country-icon {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .wrap-sgcars-hero {
    height: 700px;
  }

  .cars-container-1 {
    width: 90%;
    grid-template-columns: 1fr;
  }

  .sgcars-calculate-modal .modal-dialog {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 576px) {
  .sgcars-header {
    width: 90%;
    margin: 0 auto 30px;
    border-left: 15px solid $color-blue;

    & .sgcars-breadcrumbs {
      flex-wrap: wrap;

      & a,
      & h2 {
        font-size: 16px;
        letter-spacing: 0;
      }
    }
  }

  .sgcars-item-header {
    & > p {
      font-size: 14px;
      margin-bottom: 0px;
    }

    & > h4 {
      font-size: 20px;
    }

    & > .cars-card-item-country {
      font-size: 14px;

      & > .cars-card-item-country-icon {
        font-size: 20px;
      }
    }
  }

  .sgcars-item-banner {
    width: 100%;
    margin: 20px auto;

    & img {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
    }
  }

  .sgcars-item-carousel {
    & .sgcars-item-carousel-img {
      width: 100%;
      height: 300px;
      max-height: 300px;
      object-fit: cover;
    }

    & .carousel-control-prev {
      left: -20px;
    }
    & .carousel-control-next {
      right: -20px;
    }
  }

  .sgcars-item-attribute {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;

    & .sgcars-attri_item {
      padding: 15px 10px 10px;

      & > img {
        width: 30px;
        height: 30px;
      }
      & > p {
        font-size: 9px;
      }
      & > h5 {
        font-size: 9px;
      }
    }
  }

  .sgcars-item-dutylist {
    & > h5 {
      font-size: 16px;
    }

    & .sgcars-item-dutylist-table {
      border-radius: 15px;
    }
    & .sgcars-item-dutylist-table thead {
      font-size: 12px;
    }
    & .sgcars-item-dutylist-table td {
      font-size: 12px;
    }
  }

  .sgcars-item-description {
    & > h5 {
      font-size: 16px;
    }
    & > p {
      font-size: 12px;
    }
  }

  .sgcars-loan-calculator .alert {
    & p {
      font-size: 12px;
    }
  }

  .sgcars-loan-sec-1 {
    // grid-template-columns: 1fr;
    // gap: 20px;
    // margin: 0 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    & .sgcars-loan-item-1 {
      width: 100%;
    }
    & .mob-sgcars-loan-item-2 {
      width: 47%;
    }
  }

  .sgcars-loan-item-1 {
    & p {
      font-size: 12px;
    }
  }

  .sgcars-loan-item-2 {
    & p {
      font-size: 12px;
    }
  }

  .sgcars-calculate-modal .modal-dialog {
    width: 95%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .sgcars-calculate-modal .modal-header {
    padding: 16px 20px;
  }
  .sgcars-calculate-modal .modal-body {
    padding: 0 20px;
  }
  .sgcars-calculate-modal .modal-footer {
    padding: 0 20px 20px;
  }

  .sgcars-accord-roadtax {
    &
      .fin-tab-pane-2-content
      > .fin-tab-pane-2-input
      > .fin-tab-pane-2-saloon-type {
      padding: 0 !important;
      gap: 20px;
    }
  }
  .sgcars-accord-etc
    .sgcars-accord-item
    .sgcars-accord-roadtax
    .fin-tab-pane-2-saloon-type-1
    > h5 {
    font-size: 12px;
  }

  .sgcars-calculate-modal.mpayment-modal {
    padding: 0;
    & .modal-dialog {
      width: 95%;
      max-width: 900px;
      margin: 100px auto 50px;
    }

    & .pcars-container {
      width: 100%;
    }
    & .modal-footer {
      padding: 0 0 30px;
      width: 85%;
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      margin: 0 auto;

      & button:nth-child(2) {
        background-color: rgb(216, 75, 75);
      }
    }
  }

  .sgcars-calculate-modal .sgcars-modalbody-3 {
    grid-template-columns: 1fr;
  }

  // payment modal
  .sgcars-calculate-modal.mpayment-modal {
    & .pcars-price-summary {
      & .pcars-summary-item-2 {
        & > h5 {
          font-size: 14px;
        }

        & .pcars-summary-item-2-wrap {
          & > p {
            font-size: 12px;
          }
        }
      }
    }
  }
  .sgcars-calculate-modal.mpayment-modal .pcars-payment-method > h5,
  .sgcars-calculate-modal.mpayment-modal .pcars-payment-details > h5 {
    font-size: 14px;
    font-weight: 600;
  }

  .sgcars-calculate-modal.mpayment-modal
    .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-1
    .pcars-payment-details-item-1-1,
  .sgcars-calculate-modal.mpayment-modal
    .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-2
    .pcars-payment-details-item-2-1 {
    font-size: 13px;
  }

  .sgcars-calculate-modal.mpayment-modal
    .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-1
    .pcars-payment-details-item-1-2,
  .sgcars-calculate-modal.mpayment-modal
    .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-2
    .pcars-payment-details-item-2-2 {
    font-size: 15px;
  }
}
