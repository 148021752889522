.relative {
  position: relative;
}
.hidden {
  display: none !important;
}
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.gap-x-2 {
  column-gap: 1em;
}
.gap-x-4 {
  column-gap: 2em;
}
.w-full {
  width: 100%;
}
.ml-0 {
  margin-left: 0;
}
.mt-4 {
  margin-top: 2em;
}
.mt-5 {
  margin-top: 2.5em;
}
.mb-5 {
  margin-bottom: 2.5em;
}
.font-bold {
  font-weight: bold;
}
.cursor-pointer {
  cursor: pointer;
}
.fin-header {
  margin-left: 0 !important;
}
.import-tab-p {
  font-size: 21px;
  font-weight: bold;
  color: #182b89;
  margin-top: 10px;
}
.pills_tab {
  height: 250px;
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
  border-radius: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.nav-pills .nav-link.pills_tab.active,
.nav-pills .show > .nav-link.pills_tab {
  box-shadow: 0 0 10px 0 rgb(62 166 223 / 85%);
  background-color: unset;
}
.import_h2 {
  font-size: 24px;
  font-weight: bold;
  color: #182b89;
}
.import_h2 span {
  color: #3ea6df;
}
.import_container {
  border-radius: 15px;
  box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
  background-color: #fff;
  padding: 20px;
  margin-top: 40px;
}
.go_link_btn {
  border: 1px solid #182b89;
  border-radius: 15px;
  text-decoration: none;
  width: fit-content;
  padding: 8px 20px;
  background: #fff;
  color: #182b89;
  font-weight: bold;
  margin: 20px 0 20px auto;
}
.import_container_title {
  font-size: 21px;
  color: #182b89;
  font-weight: bold;
}
.import_container_title span {
  font-weight: normal;
}
.import_container_content {
  color: #182b89;
  font-size: 18px;
  margin-top: 5px;
}
.import_link {
  color: #182b89;
  font-weight: bold;
  text-decoration: none;
}
.conversion_label {
  position: absolute;
  bottom: 12px;
  left: 20px;
  font-size: 14px;
  color: #182b89;
}
.input_import {
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
  background-color: #fff;
  color: #182b89;
  outline: 0;
  border: 0;
  padding: 10px 20px 10px 60px;
  font-size: 14px;
  width: 100%;
}
.input_import.selected {
  background-color: #182b89;
  color: #fff;
}
.input_title_import {
  color: #182b89;
  font-size: 14px !important;
  font-weight: normal;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.calculator_note {
  font-size: 14px;
  color: #aeaeae;
  margin: 15px 0 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
}
.fix_rate.show {
  opacity: 1;
  transform: translateX(-20px);
}
.fix_rate {
  font-size: 18px;
  color: #aeaeae;
  margin: 0;
  opacity: 0;
  font-weight: bold;
  width: fit-content;
  transition: 0.4s ease all;
}
#terms {
  width: 20px;
  height: 20px;
  box-shadow: none !important;
  border: 1px solid #182b89;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px;
  color: #000;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #182b89 !important;
}
.permit_label {
  font-size: 18px;
  color: #182b89 !important;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 576px) {
  .mobile-flex-wrap {
    flex-wrap: wrap !important;
  }
  .fin-con-1-tab-row {
    display: block !important;
  }
  .fin-con-1-nav-item.w-full {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }
  .import_h2 {
    font-size: 18px;
  }
  .import_container {
    padding: 10px 15px 15px;
  }
  .import_container_title {
    font-size: 16px;
  }
  .import_container_content {
    font-size: 14px;
  }
  .gap-x-2 {
    column-gap: 1em;
  }
}

@media (max-width: 325px) {
  .import_container_title {
    font-size: 14px;
  }
  .import_container_content {
    font-size: 13px;
  }
}

/* Blink animation */
.blink {
  box-shadow: 0 0 8px 0 #f56767 !important;
}
