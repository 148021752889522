$color-blue: #182b89;
$color-lightblue: #3ea6df;

$transition-2s: all 0.2s ease-in-out;

// hero
.wrap-contus-hero {
  width: 100%;
  height: 100vh;
  margin: -80px auto 0;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(24, 43, 136, 0.5)
    ),
    url("../images/BG-37.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.contus-hero-container {
  width: 50%;
  background-color: rgba(238, 238, 238, 0.15);
  border-radius: 0 500px 500px 0;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: grid;
  grid-template-columns: 1fr;
  padding: 140px 0;
  align-items: center;
  justify-content: center;

  & > h1 {
    width: 70%;
    margin: 0 auto 20px;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    display: grid;
    grid-template-columns: 1fr;
    & > span {
      font-size: 50px;
    }
  }

  & > p {
    width: 70%;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
  }
}

// content
.contus-header {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto 30px;
  padding: 0 0 0 20px;
  border-left: 20px solid $color-blue;

  & > h2 {
    color: $color-blue;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
}

.contus-container-1 {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto;
  padding: 20px 0 60px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 60px;
}

.contus-form-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  align-items: center;

  & input {
    background-color: #fff;
    font-size: 14px;
    font-weight: normal;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
  }
  & textarea {
    background-color: #fff;
    height: 150px;
    font-size: 14px;
    font-weight: normal;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    resize: none;
  }
  & button {
    color: #fff;
    background-color: $color-lightblue;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    outline: none;
    transition: $transition-2s;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    &:hover {
      background-color: #64b6e3;
    }
    &:focus {
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
      -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
      -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    }
  }

  & .form-control {
    &:focus {
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    }
  }
}

.contus-item-2 {
  display: flex;
  align-items: center;

  & > img {
    width: 100%;
  }
}

.contus-container-2 {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto;
  padding: 0 0 60px;

  & > p {
    color: $color-blue;
    font-size: 18px;
    font-weight: normal;
    text-align: center;
  }
}

.contus-form-container.was-validated {
  gap: 15px;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  margin-bottom: 15px;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid #dc3545;
  box-shadow: none;
}
.invalid-feedback {
  font-size: 12px;
  padding-left: 10px;
}

@media screen and (min-width: 1901px) {
  .contus-hero-container {
    padding: 180px 0;
  }
}

@media screen and (max-width: 1200px) {
  .wrap-contus-hero {
    height: 800px;
  }

  .contus-hero-container {
    width: 60%;
    padding: 120px 0;

    & > h1 {
      font-size: 32px;
      display: initial;

      & > span {
        font-size: 42px;
      }
    }
    & > p {
      font-size: 20px;
    }
  }

  .contus-header {
    width: 90%;
  }

  .contus-container-1 {
    width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .wrap-contus-hero {
    height: 700px;
  }

  .contus-hero-container {
    width: 65%;
    padding: 90px 0;

    & > h1 {
      font-size: 32px;
      display: initial;

      & > span {
        font-size: 42px;
      }
    }
    & > p {
      font-size: 20px;
    }
  }

  // content
  .contus-container-1 {
    width: 90%;
    grid-template-columns: 1fr;
    gap: 60px;
    padding: 20px 0 40px;
  }

  .contus-container-2 {
    width: 80%;
    padding: 0 0 60px;

    & > p {
      color: $color-blue;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 576px) {
  .contus-hero-container {
    width: 80%;
    padding: 50px 0;

    & > h1 {
      font-size: 24px;

      & > span {
        font-size: 30px;
      }
    }
    & > p {
      font-size: 16px;
    }
  }

  //content
  .contus-header {
    width: 90%;
    border-left: 15px solid $color-blue;
    & > h2 {
      font-size: 20px;
    }
  }

  .contus-container-1 {
    grid-template-columns: 1fr;
    gap: 60px;
    padding: 20px 0;
  }

  .contus-form-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    align-items: center;

    & input {
      -webkit-appearance: none;
    }
    & textarea {
      -webkit-appearance: none;
    }
  }

  .contus-container-2 {
    width: 80%;
    padding: 0 0 60px;

    & > p {
      color: $color-blue;
      font-size: 13px;
    }
  }
}
