$color-blue: #182b89;
$color-lightblue: #3ea6df;

$transition-2s: all 0.2s ease-in-out;

// spinner & error message
.LoadingSpinner {
  text-align: center;
  padding: 30px 0;

  & .text-info {
    color: $color-blue !important;
  }
}

// hero
.wrap-cars-hero {
  width: 100%;
  height: 100vh;
  margin: -80px auto 0;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(24, 43, 136, 0.1),
      rgba(24, 43, 136, 0.3),
      rgba(24, 43, 136, 0.5),
      rgba(24, 43, 136, 0.6),
      rgba(24, 43, 136, 0.7)
    ),
    url("../../images/BG-23-02.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.cars-hero-container {
  width: 50%;
  background-color: rgba(238, 238, 238, 0.15);
  border-radius: 0 500px 500px 0;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: grid;
  grid-template-columns: 1fr;
  padding: 140px 0;
  align-items: center;
  justify-content: center;

  & > h1 {
    width: 70%;
    margin: 0 auto 20px;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    display: grid;
    grid-template-columns: 1fr;
    & > span {
      font-size: 50px;
    }
  }

  & > p {
    width: 70%;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
  }
}

// content
.wrap-cars {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 90px 0;
}

.cars-header {
  width: 80%;
  max-width: 1600px;
  margin: 0 auto 60px;
  padding: 0 0 0 20px;
  border-left: 20px solid $color-blue;

  & > h2 {
    color: $color-blue;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
}

.cars-container-1 {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 70px;
}

// country header
.cars-country {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin: 0 auto 50px;
}
.cars-country-item {
  height: auto;
  min-height: 250px;
  border-radius: 15px;
  box-shadow: 0 0 5px 0 rgba(62, 166, 223, 0.3);

  &.japan {
    background-image: url("../../images/BG-23.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  &.uk {
    background-image: url("../../images/BG-41.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  & .cars-country-item-1 {
    color: #fff;
    background-image: linear-gradient(
      to top,
      rgba(24, 43, 136, 0.6),
      rgba(24, 43, 136, 0.6)
    );
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    border-radius: 15px;
    backdrop-filter: blur(1px);
    text-decoration: none;

    & img {
      width: auto;
      height: 60px;
      object-fit: cover;
      margin-bottom: 20px;
    }
  }
}

// filter

// mobile view
.cars-c-1-item-mobile {
  display: none;
}

// desktop
.cars-c-1-item {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 15px;

  & > .cars-c-1-item-header {
    color: #fff;
    background: $color-blue;
    padding: 10px 20px;
    border-radius: 15px 15px 0 0;
    & > span {
      font-size: 18px;
      font-weight: 500;
    }
  }

  & > .cars-c-1-input-search {
    width: 90%;
    margin: 20px auto 10px;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(62, 166, 223, 0.3);

    & .input-group-text {
      color: #989898;
      background: #fff;
      border: none;
      border-radius: 10px;
    }

    & .form-control {
      color: $color-blue;
      font-size: 13px;
      font-weight: bold;
      padding: 8px 5px 8px 0;
      box-shadow: none;
      transition: none;
      border: none;
      border-radius: 10px;

      &:focus {
        border: none;
      }
    }

    & input::-webkit-input-placeholder {
      /* Edge */
      color: #989898;
      font-size: 11px;
      font-weight: normal;
    }
    & input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #989898;
      font-size: 11px;
      font-weight: normal;
    }
    & input::placeholder {
      color: #989898;
      font-size: 11px;
      font-weight: normal;
    }
  }

  & > .cars-c-1-item-body {
    padding: 0 10px 30px;
    border-radius: 15px 15px 0 0;
    & > span {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

// category
.cars-c-2-category {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 50px;

  & .cars-c-2-cate-item {
    height: 200px;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    opacity: 1;
    // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #3da6df);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    transition: $transition-2s;
    &:hover {
      opacity: 0.85;
    }
  }

  & h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 25px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      #3da6df
    );
  }
}

// dropdown
.cars-c-1-filter-item {
  & h3 {
    color: $color-blue;
    font-size: 16px;
    font-weight: bold;
    padding: 0 0 10px;
    // margin: 0 0 10px;
    border-bottom: 1px solid #e6e6e6;
  }

  & .btn {
    color: #989898;
    background-color: transparent;
    width: 100%;
    margin: 0;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    font-weight: normal;
    border: none;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0;
    overflow-x: hidden;

    &:focus {
      box-shadow: none;
    }

    &.dropdown-toggle::after {
      border: none;
      width: 12px;
      height: 12px;
      background-image: url("../../images/right-arrow-02.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      content: "";
    }
  }

  &.haveFilter {
    & .btn-primary {
      color: $color-blue;
      font-weight: bold !important;
    }
  }

  &.show > .btn-primary.dropdown-toggle {
    color: $color-blue;
    background-color: transparent;
    font-weight: bold;
    border-bottom: 1px solid #e6e6e6;
  }
  &.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;

    &.dropdown-toggle::after {
      background-image: url("../../images/right-arrow-01.png");
    }
  }

  & .dropdown-menu {
    background-color: #fff;
    min-width: 350px;
    width: fit-content;
    max-height: 450px;
    overflow-y: scroll;
    padding: 15px 15px 15px;
    border: none;
    border-radius: 15px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
    &::-webkit-scrollbar {
      display: initial;
      width: 5px;
      height: 5px; /* width of the entire scrollbar */
      border: none;
    }

    &::-webkit-scrollbar-track {
      background: #fff !important; /* color of the tracking area */
      margin: 15px 0;
      border: none;
      z-index: 10;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-blue; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
    }
  }
  & .dropdown-menu.show {
    transform: translate(305px, 0px) !important;
  }

  & .dropdown-item {
    color: #989898;
    padding: 10px;
    font-size: 13px;
    border-bottom: 1px solid #e6e6e6;
    &:hover {
      color: $color-blue;
      background-color: transparent;
    }
    &:nth-last-child(1) {
      border: none;
      padding-bottom: 0;
    }
  }

  & .cars-filter-item-linediv {
    border-bottom: 1px solid #e6e6e6;
  }

  & .save-filter-option-btn {
    color: #fff;
    background-color: $color-blue;
    width: 100%;
    margin: 20px auto 0;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    outline: 0;
    border: none;
    border-radius: 10px;
    transition: $transition-2s;
    &:hover {
      background-color: #3044a7;
    }
  }
}

.cars-c-1-item-search {
  color: #fff;
  background-color: $color-lightblue;
  width: 90%;
  margin: 20px auto 0;
  padding: 13px 0;
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  outline: 0;
  border: none;
  border-radius: 10px;
  transition: $transition-2s;
  &:hover {
    background-color: #64b6e3;
  }
}

.cars-filter-options-item-search {
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgba(62, 166, 223, 0.3);

  & .input-group-text {
    color: #989898;
    background: #fff;
    border: none;
    border-radius: 10px;
  }

  & .form-control {
    color: $color-blue;
    font-size: 12px;
    font-weight: bold;
    padding: 8px 5px 8px 0;
    box-shadow: none;
    transition: none;
    border: none;
    border-radius: 10px;

    &:focus {
      border: none;
    }
  }

  & input::-webkit-input-placeholder {
    /* Edge */
    color: #989898;
    font-size: 11px;
    font-weight: normal;
  }
  & input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #989898;
    font-size: 11px;
    font-weight: normal;
  }
  & input::placeholder {
    color: #989898;
    font-size: 11px;
    font-weight: normal;
  }
}

// range
.cars-c-1-filter-range {
  display: grid;
  grid-template-columns: 1fr;
  margin: 10px auto;

  & input {
    border-radius: 0;
    &:nth-last-child(2) {
      z-index: 5;
    }
    &:nth-last-child(1) {
      margin-left: -5px;
    }
  }
}

.cars-c-1-filter-details {
  margin: 0 auto 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  & > p {
    display: grid;
    grid-template-columns: 1fr;
    color: $color-blue;
    font-size: 14px;
    font-weight: normal;
    & > span {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.cars-c-1-filter-option {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  & > button {
    color: #fff;
    background-color: $color-lightblue;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    outline: 0;
    border: none;
    border-radius: 10px;
    transition: $transition-2s;
    &:hover {
      background-color: #64b6e3;
    }
    &:focus {
      background-color: #64b6e3;
    }
  }
}

.cars-content-2 {
  width: 100%;
  margin: 0 0 0 auto;

  & .cars-c-2-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }

  & .ant-empty {
    margin-top: 120px;
  }
}

// cards
.cars-card-item {
  height: auto;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 4px 0 rgba(62, 166, 223, 0.3);
  cursor: pointer;

  & > figure {
    width: 100%;
    margin: 0;
    border-radius: 15px 15px 0 0;
    // border-bottom: 1px solid #e6e6e6;
    & > img {
      width: 100%;
      height: 200px;
      max-height: 200px;
      object-fit: cover;
      border-radius: 15px 15px 0 0;
    }
  }

  & .cars-card-item-body {
    height: 100%;
    padding: 10px 10px 15px;
    display: flex;
    flex-direction: column;

    & .cars-card-item-brand {
      color: $color-blue;
      font-size: 12px;
      font-weight: 600;
      margin: 0 0 10px;
    }

    & .cars-card-item-title {
      color: $color-blue;
      min-height: 34px;
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 10px;
    }

    & .cars-card-item-country {
      color: #989898;
      font-size: 12px;
      font-weight: normal;
      margin: 0 0 10px;

      & .cars-card-item-country-icon {
        color: #989898;
        font-size: 17px;
        margin-right: 8px;

        & path {
          stroke: #989898;
        }
      }
    }

    & .cars-card-item-pricing {
      &-default {
        color: $color-lightblue;
        font-size: 16px;
        font-weight: bold;
      }
      &-convertedrate {
        color: $color-lightblue;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}

// popover filter (new)
.v2-filter-popover {
  // transform: translate(460px, 1200px) !important;
  // transform: translate(550px, 1200px) !important;
  background-color: #fff;
  min-width: 350px;
  width: fit-content;
  max-height: 450px;
  overflow-y: scroll;
  margin-left: 20px;
  padding: 15px 15px 3px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
  &::-webkit-scrollbar {
    display: initial;
    width: 5px;
    height: 5px; /* width of the entire scrollbar */
    border: none;
  }
  &::-webkit-scrollbar-track {
    background: #fff !important; /* color of the tracking area */
    margin: 15px 0;
    border: none;
    z-index: 10;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-blue; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }

  & .popover-header {
    color: $color-blue;
    background: #fff !important;
    padding: 0 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & svg {
      color: #989898;
      font-size: 16px;
      font-weight: normal;
      cursor: pointer;
    }
  }

  & .popover-body {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0;

    & button {
      color: #989898;
      background-color: transparent;
      width: 100%;
      margin: 0;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      font-weight: normal;
      outline: none;
      text-align: left;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #e6e6e6;
      overflow-x: hidden;
      &:hover {
        color: $color-blue;
      }
      &:focus {
        box-shadow: none;
      }

      &:nth-last-child(1) {
        border: none;
      }
    }

    & .selectedFilter {
      color: $color-blue;
      font-weight: bold;
    }
  }
}
.v2-filter-triggerBtn {
  color: #989898;
  background-color: transparent;
  width: 100%;
  margin: 0;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  border: none;
  font-size: 13px;
  font-weight: normal;
  border-bottom: 1px solid #e6e6e6;
  overflow-x: hidden;
  & > svg {
    font-size: 14px;
    margin: 10px 0;
  }
  & .haveFilter {
    color: $color-blue;
    font-weight: bold;
  }

  &[aria-describedby="popover-basic"] {
    color: $color-blue;

    & > p {
      font-weight: bold !important;
    }
  }
}
.v2-applied-filter {
  color: #fff;
  background-color: $color-lightblue;
  width: fit-content;
  // max-width: 120px !important;
  // max-height:
  padding: 10px 12px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;

  & > div {
    max-width: 70px !important;
    overflow: hidden;
  }
  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 14px;
    margin-left: 5px;
  }
}

// pagination
.cars-antd-pagination {
  margin: 60px auto 0;
  text-align: center;
  justify-content: center;

  & .ant-pagination-item-link {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  & .anticon {
    vertical-align: 0 !important;
  }
  & .ant-pagination-item-active {
    border: 1px solid $color-lightblue;
    color: $color-blue;
  }
}

@media screen and (min-width: 1901px) {
  .cars-hero-container {
    padding: 180px 0;
  }

  .cars-container-1 {
    grid-template-columns: 350px 1fr;
  }

  .cars-content-2 {
    width: 90%;
    margin: 0 0 0 auto;

    & .cars-c-2-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
    }
  }
  .cars-c-1-filter-item .dropdown-menu.show {
    transform: translate(355px, 0px) !important;
  }
}

@media screen and (max-width: 1200px) {
  .wrap-cars-hero {
    height: 800px;
  }

  .cars-header {
    width: 90%;
  }

  .cars-hero-container {
    width: 60%;
    padding: 120px 0;

    & > h1 {
      font-size: 32px;
      display: initial;

      & > span {
        font-size: 42px;
      }
    }
    & > p {
      font-size: 20px;
    }
  }

  // category
  .cars-c-2-category {
    gap: 25px;
  }

  // car cards
  .cars-card-item > figure > img {
    height: 180px;
  }
}

@media screen and (max-width: 1024px) {
  .wrap-cars-hero {
    height: 700px;
  }

  .cars-hero-container {
    width: 65%;
    padding: 90px 0;

    & > h1 {
      font-size: 32px;
      display: initial;

      & > span {
        font-size: 42px;
      }
    }
    & > p {
      font-size: 20px;
    }
  }

  .cars-header {
    width: 90%;
    margin: 0 auto 30px;
  }

  // country component
  .cars-country-item {
    min-height: 200px;
  }

  // filter component
  // desktop
  .cars-c-1-item {
    display: none;
  }

  // mobile view
  .cars-c-1-item-mobile {
    display: initial;
  }

  .cars-c-1-item-header {
    color: $color-blue;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 20px;
  }

  .cars-c-1-item-header-item {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 10px;

    & > .cars-c-1-input-search {
      width: 100%;
      height: 40px;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: 0 0 5px 0 rgba(62, 166, 223, 0.3);

      & .input-group-text {
        color: #989898;
        background: #fff;
        border: none;
        border-radius: 10px;
      }

      & .form-control {
        color: $color-blue;
        font-size: 14px;
        font-weight: bold;
        padding: 8px 5px 8px 0;
        box-shadow: none;
        transition: none;
        border: none;
        border-radius: 10px;

        &:focus {
          border: none;
        }
      }

      & input::-webkit-input-placeholder {
        /* Edge */
        color: #989898;
        font-size: 12px;
        font-weight: normal;
      }
      & input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #989898;
        font-size: 12px;
        font-weight: normal;
      }
      & input::placeholder {
        color: #989898;
        font-size: 12px;
        font-weight: normal;
      }
    }

    & .cars-c-1-filters-btn {
      color: #fff;
      background-color: $color-lightblue;
      width: 100%;
      height: 40px;
      font-size: 12px;
      outline: 0;
      border: none;
      border-radius: 10px;
      &:hover {
        background-color: $color-blue;
      }
    }
  }

  .cars-container-1 {
    gap: 40px;
  }

  // category
  .cars-c-2-category {
    gap: 30px;
  }

  // filters modal
  .cars-filter-modal {
    z-index: 2000;
    border-radius: 20px;
    backdrop-filter: blur(5px);

    & .modal-content {
      height: 65vh;
      border: none;
      border-radius: 20px;
    }

    & .modal-body {
      padding: 0;
      border: none;

      & .flex-column {
        background: #f2f2f7;
        border-radius: 0;
        // height: 400px;
        height: 100%;
      }
    }

    & .modal-footer {
      padding: 0;
      border: none;
      border-radius: 0 0 20px 20px;
      display: grid;
      grid-template-columns: 2fr 3fr;
      gap: 36px;

      & .cars-filter-modal-footer-1 {
        background: #f2f2f7;
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0 0 0 20px;
      }

      & .cars-filter-modal-footer-2 {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 10px 0;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }

  .cars-filter-modal-header {
    color: #fff;
    background-color: $color-blue;
    border-radius: 20px 20px 0 0;
    border: none;

    & h3 {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    & .btn-close {
      color: #fff;
      opacity: 0.9;
      background: url("../../images/closed-icon-01.png");
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: center center;
    }
  }

  .cars-filter-modal-body {
    background-color: #fff;

    & .row {
      height: 100%;
      display: grid;
      grid-template-columns: 2fr 3fr;
    }

    & .cars-filter-modal-col2 {
      padding: 20px 20px 0 0;
      width: 100%;
      height: 600px;
      overflow-x: hidden;
    }

    & .nav-pills .nav-link {
      background: transparent;
      color: $color-blue;
      padding: 14px 10px;
      font-size: 12px;
      border-left: 3px solid transparent;
      border-bottom: 1px solid #e6e6e6;
      border-radius: 0;

      &.active {
        background: transparent;
        font-weight: bold;
        border-left: 3px solid $color-blue;
      }
    }
  }

  .cars-filter-modal-item {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    overflow: scroll;
    padding: 10px;

    & button {
      color: #989898;
      background-color: #fff;
      // height: 70px;
      padding: 20px 10px;
      font-size: 12px;
      font-weight: normal;
      outline: 0;
      border: none;
      border-radius: 8px;
      box-shadow: 0 0 5px 0 rgba(62, 166, 223, 0.3);
    }

    & .selected-item {
      color: $color-blue;
      font-weight: bold;
    }

    &.cfmi-type-2 {
      grid-template-columns: 1fr;

      & button {
        padding: 10px;
        text-align: left;
      }
    }

    & h6 {
      color: $color-blue;
      font-size: 13px;
      margin-bottom: 10px;
    }

    & .cars-filter-modal-item-search {
      width: 100%;
      margin: 0 auto 20px;
      border-radius: 10px;
      box-shadow: 0 0 5px 0 rgba(62, 166, 223, 0.3);

      & .input-group-text {
        color: #989898;
        background: #fff;
        border: none;
        border-radius: 10px;
      }

      & .form-control {
        color: $color-blue;
        font-size: 12px;
        font-weight: bold;
        padding: 8px 5px 8px 0;
        box-shadow: none;
        transition: none;
        border: none;
        border-radius: 10px;

        &:focus {
          border: none;
        }
      }

      & input::-webkit-input-placeholder {
        /* Edge */
        color: #989898;
        font-size: 11px;
        font-weight: normal;
      }
      & input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #989898;
        font-size: 11px;
        font-weight: normal;
      }
      & input::placeholder {
        color: #989898;
        font-size: 11px;
        font-weight: normal;
      }
    }
  }

  .cars-filter-modal-item-2 {
    padding: 0 5px;
    & button {
      width: 100%;
      color: #fff;
      background-color: $color-lightblue;
      padding: 10px 24px;
      margin-left: auto;
      font-size: 12px;
      border: none;
      border-radius: 8px;
      outline: none;

      &:hover {
        background-color: $color-blue;
      }
    }
  }

  .modal-filter-range-title {
    color: $color-blue;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;

    & span {
      font-weight: bold;
    }
  }

  .cars-filter-modal-footer {
    background-color: #fff;

    & button {
      color: #fff;
      background-color: $color-lightblue;
      font-size: 12px;
      padding: 10px 24px;
      border: none;
      border-radius: 8px;
      outline: none;

      &:hover {
        background-color: $color-blue;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .LoadingSpinnerMobile {
    display: none;
  }

  .cars-hero-container {
    width: 80%;
    padding: 50px 0;

    & > h1 {
      font-size: 24px;

      & > span {
        font-size: 30px;
      }
    }
    & > p {
      font-size: 16px;
    }
  }

  .cars-header {
    width: 90%;
    margin: 0 auto 30px;
  }

  //country filter
  .cars-country {
    gap: 10px;
    margin-bottom: 30px;
  }
  .cars-country-item {
    min-height: 150px;
  }
  .cars-country-item .cars-country-item-1 {
    padding: 20px;
    font-size: 12px;
  }
  .cars-country-item .cars-country-item-1 img {
    height: 40px;
  }

  .cars-container-1 {
    grid-template-columns: 1fr;
    gap: 30px !important;
  }

  .cars-content-2 .cars-c-2-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .cars-card-item > figure > img {
    max-height: 220px;
    object-position: center;
  }
  .cars-c-1-filter-item .dropdown-menu {
    width: 90%;
  }
  .cars-c-1-filter-item .dropdown-menu.show {
    transform: translate(-20px, 50px) !important;
  }

  // filter component
  // desktop
  .cars-c-1-item {
    display: none;
  }

  // mobile view
  .cars-c-1-item-mobile {
    display: initial;
  }
  .cars-c-1-item-header {
    color: $color-blue;
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 10px;
  }

  .cars-c-1-item-header-item {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 10px;

    & > .cars-c-1-input-search {
      width: 100%;
      height: 40px;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: 0 0 5px 0 rgba(62, 166, 223, 0.3);

      & .input-group-text {
        color: #989898;
        background: #fff;
        border: none;
        border-radius: 10px;
      }

      & .form-control {
        color: $color-blue;
        font-size: 12px;
        font-weight: bold;
        padding: 8px 5px 8px 0;
        box-shadow: none;
        transition: none;
        border: none;
        border-radius: 10px;

        &:focus {
          border: none;
        }
      }

      & input::-webkit-input-placeholder {
        /* Edge */
        color: #989898;
        font-size: 10px;
        font-weight: normal;
      }
      & input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #989898;
        font-size: 10px;
        font-weight: normal;
      }
      & input::placeholder {
        color: #989898;
        font-size: 10px;
        font-weight: normal;
      }
    }

    & .cars-c-1-filters-btn {
      color: #fff;
      background-color: $color-lightblue;
      width: 100%;
      height: 40px;
      font-size: 12px;
      outline: 0;
      border: none;
      border-radius: 10px;
      &:hover {
        background-color: $color-blue;
      }
    }
  }

  .cars-container-1 {
    gap: 40px;
  }

  // category
  .cars-c-2-category {
    grid-template-columns: 1fr;
    gap: 10px;

    & .cars-c-2-cate-item {
      height: 170px;
      border-radius: 20px;
    }

    & h3 {
      font-size: 18px;
      border-radius: 20px;
    }
  }

  // filters modal
  .cars-filter-modal {
    z-index: 2000;
    border-radius: 20px;
    backdrop-filter: blur(5px);

    & .modal-content {
      height: 65vh;
      border: none;
      border-radius: 20px;
    }

    & .modal-body {
      padding: 0;
      border: none;

      & .flex-column {
        background: #f2f2f7;
        border-radius: 0;
        // height: 400px;
        height: 100%;
      }
    }

    & .modal-footer {
      padding: 0;
      border: none;
      border-radius: 0 0 20px 20px;
      display: grid;
      grid-template-columns: 2fr 3fr;
      gap: 36px;

      & .cars-filter-modal-footer-1 {
        background: #f2f2f7;
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0 0 0 20px;
      }

      & .cars-filter-modal-footer-2 {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 10px 0;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }

  .cars-filter-modal-header {
    color: #fff;
    background-color: $color-blue;
    border-radius: 20px 20px 0 0;
    border: none;

    & h3 {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    & .btn-close {
      color: #fff;
      opacity: 0.9;
      background: url("../../images/closed-icon-01.png");
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: center center;
    }
  }

  .cars-filter-modal-body {
    background-color: #fff;

    & .row {
      height: 100%;
      display: grid;
      grid-template-columns: 2fr 3fr;
    }

    & .cars-filter-modal-col2 {
      padding: 20px 20px 0 0;
      width: 100%;
      height: 420px;
      overflow-x: hidden;
    }

    & .nav-pills .nav-link {
      background: transparent;
      color: $color-blue;
      padding: 14px 10px;
      font-size: 12px;
      border-left: 3px solid transparent;
      border-bottom: 1px solid #e6e6e6;
      border-radius: 0;

      &.active {
        background: transparent;
        font-weight: bold;
        border-left: 3px solid $color-blue;
      }
    }
  }

  .cars-filter-modal-item {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    overflow: scroll;
    padding: 10px;

    & button {
      color: #989898;
      background-color: #fff;
      // height: 70px;
      padding: 20px 10px;
      font-size: 10px;
      font-weight: normal;
      outline: 0;
      border: none;
      border-radius: 8px;
      box-shadow: 0 0 5px 0 rgba(62, 166, 223, 0.3);
    }

    & .selected-item {
      color: $color-blue;
      font-weight: bold;
    }

    &.cfmi-type-2 {
      grid-template-columns: 1fr;

      & button {
        padding: 10px;
        text-align: left;
      }
    }

    & h6 {
      color: $color-blue;
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  .cars-c-1-filter-details {
    grid-template-columns: 1fr;
  }

  .modal-filter-range-title {
    color: $color-blue;
    font-size: 13px;
  }
  .cars-c-1-filter-details > p {
    font-size: 13px;
  }

  .cars-filter-modal-footer {
    & button {
      color: #fff;
      background-color: $color-lightblue;
      font-size: 12px;
      padding: 10px 20px;
      border: none;
      border-radius: 8px;
      outline: none;

      &:hover {
        background-color: $color-blue;
      }
    }
  }

  // car cards
  .cars-card-item > figure > img {
    height: 200px;
  }
  .cars-card-item .cars-card-item-body .cars-card-item-title {
    min-height: fit-content;
  }
}
