$color-blue: #182b89;
$color-lightblue: #3ea6df;

$transition-2s: all 0.2s ease-in-out;

// hero
.wrap-fin-hero {
  width: 100%;
  height: 100vh;
  margin: -80px auto 0;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(24, 43, 136)
    ),
    url("../../images/BG-36.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.fin-hero-container {
  width: 50%;
  background-color: rgba(238, 238, 238, 0.15);
  border-radius: 0 500px 500px 0;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: grid;
  grid-template-columns: 1fr;
  padding: 120px 0;
  align-items: center;
  justify-content: center;

  & > h1 {
    width: 70%;
    margin: 0 auto 20px;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    display: grid;
    grid-template-columns: 1fr;
    & > span {
      font-size: 50px;
    }
  }

  & > p {
    width: 70%;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
  }
}

// content
.wrap-fin {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 90px 0;
}

.fin-header {
  width: 31%;
  margin: 0 auto 60px;
  padding: 0 0 0 20px;
  border-left: 20px solid $color-blue;

  & > h2 {
    color: $color-blue;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
}

.fin-tab-pane-hr {
  margin: 40px auto;
}

// fin-container-1
.fin-container-1 {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto;
}

.fin-con-1-tab-row {
  // gap: 100px;
  flex-wrap: initial;
  justify-content: space-between;

  & .flex-column {
    gap: 25px 0;
  }
}

.fin-con-1-nav-item {
  border-radius: 15px;
  -moz-box-shadow: 0 0 10px 0 rgba(62, 166, 223, 0.45);
  -webkit-box-shadow: 0 0 10px 0 rgba(62, 166, 223, 0.45);
  box-shadow: 0 0 10px 0 rgba(62, 166, 223, 0.45);
  text-align: center;
}

.fin-con-1-nav-link {
  color: $color-blue;
  height: 170px;
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
  border-radius: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $color-blue;
  }

  &.active {
    color: #fff;
    background-color: $color-blue !important;
  }
}

// Properties
.fin-tab-pane-2-header {
  margin: 0 0 40px;

  & > h3 {
    color: $color-blue;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  & > p {
    color: #aeaeae;
    font-size: 16px;
    font-weight: normal;
  }
}

// 1

.fin-tab-pane-1-content {
  & > .fin-tab-pane-1-linediv {
    margin: 30px 0;
    border-bottom: solid 2px #d9d9d9;
  }

  & > .fin-tab-pane-1-input {
    margin-bottom: 30px;

    & > h4 {
      color: $color-blue;
      font-size: 16px;
      font-weight: normal;
      text-transform: capitalize;
      margin-bottom: 10px;
    }
    & input {
      width: 100%;
      color: $color-blue;
      background-color: #fff;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: normal;
      border: none;
      border-radius: 10px;
      outline: 0;
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
      -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    }

    & select {
      width: 100%;
      color: $color-blue;
      background-color: #fff;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: normal;
      border: none;
      border-radius: 10px;
      outline: 0;
      cursor: pointer;
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;

      & option {
        padding: 11px 0;
        border: none;
        outline: 0;
        cursor: pointer;
        text-transform: capitalize;
      }
    }
  }

  & > .fin-tab-pane-calculate-btn {
    color: #fff;
    background-color: $color-lightblue;
    width: 100%;
    padding: 12px 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    outline: 0;
    border: none;
    border-radius: 15px;
    transition: $transition-2s;
    &:hover {
      background-color: #64b6e3;
    }
  }

  & > .fin-tab-pane-1-type1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 35px;
    margin-bottom: 30px;

    & > .fin-tab-pane-1-type1-input {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
      border: none;
      border-radius: 10px;
      outline: 0;

      & span {
        color: $color-blue;
        background-color: #fff;
        margin-right: 20px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
      }

      & input {
        width: 100%;
        color: $color-blue;
        background-color: #fff;
        font-size: 14px;
        font-weight: normal;
        border: none;
        outline: 0;
      }
    }
  }
}

// 2
.fin-tab-pane-2-content {
  width: 100%;

  & > .fin-tab-pane-2-linediv {
    margin: 30px 0;
    border-bottom: solid 1px #d9d9d9;
  }

  & > .fin-tab-pane-2-input {
    margin: 30px auto;

    & > h4 {
      color: $color-blue;
      font-size: 16px;
      font-weight: normal;
      text-transform: capitalize;
      margin-bottom: 10px;
    }
    & input {
      width: 100%;
      color: $color-blue;
      background-color: #fff;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: bold;
      border: none;
      border-radius: 10px;
      outline: 0;
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    }
    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button {
      /* Chrome, Safari, Edge, Opera */
      -webkit-appearance: none;
      margin: 0;
    }
    & input[type="number"] {
      /* Firefox */
      -moz-appearance: textfield;
    }
    & input::-webkit-input-placeholder {
      /* Edge */
      color: #989898;
      font-size: 14px;
      font-weight: normal;
    }
    & input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #989898;
      font-size: 14px;
      font-weight: normal;
    }
    & input::placeholder {
      color: #989898;
      font-size: 14px;
      font-weight: normal;
    }

    & select {
      width: 100%;
      color: $color-blue;
      background-color: #fff;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: normal;
      border: none;
      border-radius: 10px;
      outline: 0;
      cursor: pointer;
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;

      & option {
        padding: 11px 0;
        border: none;
        outline: 0;
        cursor: pointer;
        text-transform: capitalize;
      }
    }

    & > .fin-tab-pane-2-saloon-type {
      margin: 12px 0 0;
      padding: 0 24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      & > .fin-tab-pane-2-saloon-type-1 {
        & > h5 {
          color: $color-blue;
          margin: 0 0 5px;
          font-size: 16px;
          font-weight: bold;
        }

        & > p {
          color: #999;
          font-size: 14px;
          font-weight: normal;
        }
      }
    }

    & > .fin-tab-pane-payment {
      color: $color-blue;
      background-color: #fff;
      padding: 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px 0 rgba(62, 166, 223, 0.45);
      text-align: center;
      font-size: 36px;
      font-weight: bold;
    }
  }

  & .fin-tab-pane-3-input {
    margin: 0 0 30px;
    & > h4 {
      color: $color-blue;
      font-size: 16px;
      font-weight: normal;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    & .fin-tab-pane-3-range {
      width: 100%;
      color: $color-blue;
      background-color: #fff;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: bold;
      border: none;
      border-radius: 10px;
      outline: 0;
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;

      & span {
        width: 50px;
      }
    }
  }

  & .fin-tab-pane-4-input {
    display: grid;
    grid-template-columns: 50px 1fr;
    margin: 0 0 30px;
    padding: 10px 20px 8px;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;

    & p {
      color: $color-blue;
      font-size: 16px;
      font-weight: normal;
      text-transform: capitalize;
      margin-bottom: 2px;
    }

    & .fin-tab-pane-4-range {
      width: 100%;
      color: $color-blue;
      background-color: #fff;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      border: none;
      border-radius: 10px;
      outline: 0;

      & span {
        width: 50px;
      }
    }
  }

  & > .fin-tab-pane-recalculate-btn {
    color: #fff;
    background-color: #ffc107;
    width: 100%;
    padding: 12px 0;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    outline: 0;
    border: none;
    border-radius: 15px;
    transition: $transition-2s;
    &:hover {
      background-color: #ffc107;
    }
  }
  & > .fin-tab-pane-calculate-btn {
    color: #fff;
    background-color: $color-lightblue;
    width: 100%;
    padding: 12px 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    outline: 0;
    border: none;
    border-radius: 15px;
    transition: $transition-2s;
    &:hover {
      background-color: #64b6e3;
    }
  }
  & > .fin-tab-pane-calculate-link {
    color: #fff;
    background-color: $color-lightblue;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    outline: 0;
    border: none;
    border-radius: 15px;
    transition: $transition-2s;
    text-decoration: none;
    &:hover {
      background-color: #64b6e3;
    }
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}

// dropdown option
.fin-cal-option {
  width: 100%;

  & .btn {
    color: $color-blue;
    background-color: #fff;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: normal;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(62, 166, 223, 0.45);
    &:focus {
      box-shadow: 0 0 10px 0 rgba(62, 166, 223, 0.45);
    }
  }

  &.show > .btn-primary.dropdown-toggle {
    color: $color-blue;
    background-color: transparent;
    font-weight: normal;
    border: none;
  }
  &.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 10px 0 rgba(62, 166, 223, 0.45);
  }

  & .dropdown-menu {
    padding: 10px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(62, 166, 223, 0.25);
  }
  & .dropdown-menu.show {
    width: 100%;
  }

  & .dropdown-item {
    color: $color-blue;
    padding: 10px;
    font-size: 13px;
    border-radius: 5px;
    &:hover {
      background-color: #f5f5f5;
    }
  }

  // dutylist dropdown
  &.dutylist .dropdown-menu.show {
    max-height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: initial;
      width: 5px; /* width of the entire scrollbar */
      border: none;
    }
    &::-webkit-scrollbar-track {
      background: #fff; /* color of the tracking area */
      margin: 20px 0;
      border: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-blue; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      width: 5px;
    }
  }
}

.fin-cal-option.imp_calculator .dropdown-menu.show {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: initial;
    width: 5px;
    height: 5px;
    border: none;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    margin: 20px 0;
    border: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-blue;
    border-radius: 20px;
    height: 5px;
  }
}

.input_title_import-result {
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
  background-color: #fff;
  color: $color-blue;
  outline: 0;
  border: 0;
  padding: 10px 20px 10px;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}

.fin-tab-duty-table {
  margin: 30px 0 0;
  color: $color-blue;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);

  & thead {
    font-size: 14px;
    & tr {
      border: none !important;
    }
    & th {
      border-bottom: 1px solid #d9d9d9 !important;
      padding: 14px;
    }
  }
  & .fin-item-dutylist-border {
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
  }

  & td {
    font-size: 14px;
    font-weight: normal;
    padding: 14px;
  }
  & td.hidden {
    display: none;
  }

  & tr:last-child td {
    border-bottom: none !important;
  }
}

.disclaimer {
  margin: 30px 0 0;
  color: $color-blue;
  font-size: 12px;
}

// fin-container-2
.fin-container-2 {
  width: 75%;
  max-width: 1500px;
  margin: 90px auto 0;

  & > h3 {
    color: $color-blue;
    margin: 0 0 50px;
    padding: 0 0 0 15px;
    font-size: 20px;
    font-weight: bold;
    border-left: 15px solid $color-blue;
  }
}

.fin-cont-2-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;

  & .fin-cont-2-item-1 {
    background-color: #fff;
    border-radius: 500px;
    padding: 90px 20px;
    box-shadow: 4px 4px 15px 0 rgba(61, 165, 223, 0.3);
    cursor: pointer;
    transition: $transition-2s;
    &:hover {
      box-shadow: 4px 4px 15px 0 rgba(61, 165, 223, 0.6);
    }

    & > a {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
      text-decoration: none;
    }

    & h4 {
      color: $color-blue;
      font-size: 21px;
      font-weight: 500;
    }
  }
}
.fin-tab-duty-table .table > :not(caption) > * > * {
  color: $color-blue;
}

.impRef-to-payment-btn {
  color: #fff;
  background-color: $color-lightblue;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  outline: 0;
  border: none;
  border-radius: 15px;
  transition: $transition-2s;
  text-decoration: none;
  &:hover {
    color: #fff;
    background-color: #64b6e3;
  }

  &.disabled {
    background-color: #bcbcbc;
    cursor: default;
  }
}

@media screen and (min-width: 1901px) {
  .fin-hero-container {
    padding: 180px 0;
  }

  .fin-header {
    width: 30%;
    margin: 0 auto 60px;
    padding: 0 0 0 20px;
    border-left: 20px solid $color-blue;

    & > h2 {
      color: $color-blue;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.5px;
    }
  }
  .fin-container-2 {
    width: 65%;
    max-width: 1500px;
    margin: 90px auto 0;
  }
  .fin-cont-2-item {
    & .fin-cont-2-item-1 {
      padding: 100px 20px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .wrap-fin-hero {
    height: 800px;
  }
  .fin-hero-container {
    width: 60%;
    padding: 120px 0;

    & > h1 {
      font-size: 32px;
      display: initial;

      & > span {
        font-size: 42px;
      }
    }
    & > p {
      font-size: 20px;
    }
  }

  .wrap-fin {
    padding: 20px 0 90px;
  }

  .fin-header {
    width: 36%;
  }

  .fin-container-1 {
    width: 90%;
  }

  .fin-con-1-tab-row-col {
    width: 30%;
  }

  .fin-con-1-nav-link {
    height: 130px;
    font-size: 16px;
  }

  .fin-container-2 {
    width: 90%;
    margin: 60px auto 0;
  }

  .fin-cont-2-item .fin-cont-2-item-1 h4 {
    font-size: 18px;
  }

  .fin-tab-duty-table {
    & td {
      padding: 12px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .wrap-fin-hero {
    height: 700px;
  }
  .fin-hero-container {
    width: 65%;
    padding: 90px 0;

    & > h1 {
      font-size: 32px;
      display: initial;

      & > span {
        font-size: 42px;
      }
    }
    & > p {
      font-size: 20px;
    }
  }

  .wrap-fin {
    padding: 0 0 90px;
  }

  .fin-header {
    width: 90%;
    margin: 0 auto 60px;
    padding: 0 0 0 20px;

    & > h2 {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .fin-cal-option .btn {
    font-size: 13px;
  }
  .fin-tab-pane-2-content > .fin-tab-pane-2-input {
    margin-bottom: 20px;
  }
  .fin-tab-pane-2-content > .fin-tab-pane-2-input > h4 {
    font-size: 14px;
  }

  // useful links
  .fin-cont-2-item .fin-cont-2-item-1 {
    padding: 60px 20px;
    &:hover {
      box-shadow: 4px 4px 15px 0 rgba(61, 165, 223, 0.3);
    }
  }

  // dutylist overflow
  .fin-tab-pane-2-input.dutylist-input {
    // max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    & .fin-cal-option.show > .btn-primary.dropdown-toggle:focus {
      box-shadow: none;
    }
  }
  .fin-cal-option.dutylist {
    & .btn {
      max-width: 400px;
      overflow-y: hidden;
      box-shadow: none;
    }
  }

  .fin-cont-2-item .fin-cont-2-item-1 h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .fin-hero-container {
    width: 80%;
    padding: 50px 0;

    & > h1 {
      font-size: 24px;

      & > span {
        font-size: 30px;
      }
    }
    & > p {
      font-size: 16px;
    }
  }

  .fin-con-1-tab-row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .fin-con-1-tab-row-col {
    width: 100%;
    overflow: scroll;
    overflow-y: hidden;

    & .flex-column {
      margin-left: -10px;
      padding: 10px 10px;
      display: flex;
      justify-content: flex-start;
      flex-direction: row !important;
      overflow: scroll;
      gap: 0 20px;
      width: max-content;
    }
  }

  .fin-con-1-nav-item {
    width: 200px;
    height: 100px;
  }

  .fin-con-1-nav-link {
    width: 200px;
    height: 100px;
    font-size: 14px;
  }

  // content
  .fin-tab-pane-1-header {
    & > h3 {
      font-size: 16px;
    }
    & > p {
      font-size: 14px;
    }
  }
  .fin-tab-pane-2-header {
    & > h3 {
      font-size: 16px;
    }
    & > p {
      font-size: 14px;
    }
  }

  .fin-tab-pane-1-input {
    & select {
      -webkit-appearance: none;
    }
  }

  .fin-tab-pane-2-input {
    & select {
      -webkit-appearance: none;
    }
  }

  .fin-tab-pane-2-content > .fin-tab-pane-2-input {
    margin-bottom: 20px;
    & > h4 {
      font-size: 13px;
      font-weight: 500;
    }

    & > .fin-tab-pane-2-saloon-type {
      padding: 0;
      & > .fin-tab-pane-2-saloon-type-1 {
        & > h5,
        & > p {
          font-size: 12px;
        }
      }
    }
  }
  .fin-tab-pane-2-content .fin-tab-pane-3-input > h4 {
    font-size: 13px;
    font-weight: 500;
  }
  .fin-tab-pane-2-content > .fin-tab-pane-2-input > .fin-tab-pane-payment {
    font-size: 30px;
  }

  .fin-cal-option .btn {
    font-size: 12px;
  }
  .fin-cal-option .dropdown-item {
    font-size: 10px;
  }
  .fin-cal-option.dutylist .dropdown-menu.show {
    overflow-x: auto;
  }

  .fin-cont-2-item {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .fin-cal-option.dutylist {
    & .btn {
      max-width: 380px;
      overflow-y: hidden;
      box-shadow: none;
    }
  }

  .fin-tab-duty-table .table > :not(caption) > * > * {
    font-size: 13px;
  }

  .fin-tab-duty-table {
    border-radius: 15px;
    & td {
      padding: 12px;
    }
  }

  // import
  .imp-cal-item-1 {
    display: grid !important;
    gap: 30px;
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }
  .imp-cal-item-2 {
    margin: 30px auto !important;
  }
  .imp-cal-item-3 {
    display: grid !important;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    margin: 30px auto !important;

    .input_import {
      font-size: 12px;
    }
  }
  .imp-cal-item-4 {
    display: grid !important;
    gap: 0;
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }
  .calculator_note {
    font-size: 12px;
  }

  .impRef-to-payment-btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .fin-cal-option.dutylist {
    & .btn {
      max-width: 330px;
      padding: 10px 10px 10px 20px;
    }
  }
}
