$color-blue: #182b89;
$color-lightblue: #3ea6df;

$transition-2s: all 0.2s ease-in-out;

.wrap-payment {
  width: 100%;
  height: auto;
}

.pcars-container {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto 90px;
}

.pcars-img-holder {
  width: auto;
  height: 450px;
  margin: 0 0 30px;
  text-align: center;

  & > img {
    width: auto;
    height: 100%;
    border-radius: 15px;
  }
}

.pcars-details-header {
  margin: 0 0 30px;
  & > p {
    color: $color-blue;
    font-size: 18px;
    font-weight: normal;
    margin: 0 0 10px;
  }

  & > h3 {
    color: $color-blue;
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 10px;
  }

  & > .cars-card-item-country {
    color: #989898;
    font-size: 18px;
    font-weight: normal;

    & > .cars-card-item-country-icon {
      color: #989898;
      font-size: 25px;
      margin-right: 8px;

      & path {
        stroke: #989898;
      }
    }
  }
}

.pcars-price-summary {
  width: 100%;

  & .pcars-summary-item-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 0 0 30px;

    & .pcars-summary-item-1-1 {
      color: $color-blue;
      font-size: 20px;
      font-weight: 600;
    }

    & .pcars-summary-item-1-2 {
      color: $color-lightblue;
      font-size: 36px;
      font-weight: bold;
      text-align: right;
      line-height: 1;
      & > span {
        color: $color-blue;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  & .pcars-summary-item-2 {
    background-color: #f2f2f7;
    padding: 30px;
    border-radius: 8px;
    margin: 0 0 10px;

    & > h5 {
      color: $color-blue;
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 20px;
    }

    & .pcars-summary-item-2-wrap {
      display: grid;
      grid-template-columns: 1fr 200px;
      align-items: center;
      margin: 15px 0 0;

      & > p {
        color: $color-blue;
        font-size: 18px;
        font-weight: normal;
      }

      & .pcars-summary-item-2-2 {
        text-align: right;
      }
    }
  }

  & .pcars-summary-item-3 {
    width: 100%;
    margin: 0 0 20px;

    & p {
      color: $color-blue;
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.pcars-price-payment {
  background-color: #fff;
  margin: 0 0 30px;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);

  & .pcars-pricing-item-1 {
    display: grid;
    grid-template-columns: 90px 2fr 1fr;
    gap: 30px;
    align-items: center;
    margin: 0 0 10px;

    & .pcars-pricing-item-1-1 {
      color: #fff;
      background-color: #30d158;
      width: 100%;
      padding: 7px 0;
      border-radius: 6px;
      font-size: 12px;
      text-align: center;
    }

    & .pcars-pricing-item-1-2 {
      & h6 {
        color: $color-blue;
        font-size: 20px;
        font-weight: bold;
      }
      & p {
        color: $color-blue;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
      }
    }

    & .pcars-pricing-item-1-3 {
      color: $color-blue;
      width: 180px;
      display: flex;
      align-items: center;
      padding: 7px 20px;
      margin: 0 0 0 auto;
      font-size: 20px;
      font-weight: bold;
      text-align: right;
      border-radius: 10px;
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
      -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;

      & input {
        color: $color-lightblue;
        width: 150px;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        margin: 0;
        border: none;
        outline: 0;
      }
      & input::-webkit-outer-spin-button,
      & input::-webkit-inner-spin-button {
        /* Chrome, Safari, Edge, Opera */
        -webkit-appearance: none;
        margin: 0;
      }
      & input[type="number"] {
        /* Firefox */
        -moz-appearance: textfield;
      }
      & input::-webkit-input-placeholder {
        /* Edge */
        color: #989898;
        font-size: 20px;
        font-weight: bold;
      }
      & input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #989898;
        font-size: 20px;
        font-weight: bold;
      }
      & input::placeholder {
        color: #989898;
        font-size: 20px;
        font-weight: bold;
      }

      & .ant-input-number {
        border: none;
        & .ant-input-number-handler-wrap {
          display: none;
        }
        & .ant-input-number-input-wrap {
          border: none;
        }
        &-focused {
          border: none;
          box-shadow: none;
        }
      }
    }
  }

  & .pcars-pricing-item-2 {
    display: grid;
    grid-template-columns: 90px 2fr 1fr;
    gap: 30px;
    align-items: center;

    & .pcars-pricing-item-2-1 {
      color: #fff;
      background-color: #999;
      width: 100%;
      padding: 7px 0;
      border-radius: 6px;
      font-size: 12px;
      text-align: center;
    }

    & > h6 {
      color: $color-blue;
      font-size: 20px;
      font-weight: bold;
    }
    & > p {
      color: #aeaeae;
      font-size: 30px;
      font-weight: bold;
      text-align: right;
    }
  }
}

.pcars-payment-method {
  margin: 0 0 30px;

  & > h5 {
    color: $color-blue;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 15px;
  }
}

.pcars-p-method-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.pcars-p-method-1 {
  & button {
    background-color: #fff;
    width: 100%;
    height: 100px;
    max-height: 100px;
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    padding: 20px 15px;
    gap: 15px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);

    & .paymethod-icon-1 {
      color: #aeaeae;
    }

    // selected payment method
    &.selected {
      & .paymethod-icon-1 {
        color: $color-blue;
      }
    }

    & .paymethod-var-item-1 {
      display: grid;
      grid-template-columns: 60px 1fr;
      gap: 30px;

      & figure {
        width: 100%;
        margin: 0;

        & > img {
          width: 100%;
        }
      }

      & div {
        height: auto;
        margin: 0;
        padding: 10px 0;

        & h6 {
          color: $color-blue;
          font-size: 13px;
          font-weight: 600;
          text-align: left;
        }
        & p {
          color: #000;
          font-size: 12px;
          font-weight: normal;
          text-align: left;
        }
      }
    }
  }
}

.pcars-p-method-2 {
  & button {
    background-color: #fff;
    width: 100%;
    height: 100px;
    max-height: 100px;
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    padding: 20px 15px;
    gap: 15px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);

    & .paymethod-icon-1 {
      color: #aeaeae;
    }

    // selected payment method
    &.selected {
      & .paymethod-icon-1 {
        color: $color-blue;
      }
    }

    & .paymethod-var-item-1 {
      display: grid;
      grid-template-columns: 60px 1fr;
      gap: 30px;

      & figure {
        width: 100%;
        margin: 0;

        & > img {
          width: 80%;
        }
      }

      & div {
        height: auto;
        margin: 0;
        padding: 10px 0;

        & h6 {
          color: $color-blue;
          font-size: 13px;
          font-weight: 600;
          text-align: left;
        }
        & p {
          color: #000;
          font-size: 12px;
          font-weight: normal;
          text-align: left;
        }
      }
    }
  }
}

.pcars-payment-details {
  margin: 0 0 25px;

  & > h5 {
    color: $color-blue;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 15px;
  }

  & .pcars-payment-details-item {
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);

    & .pcars-payment-details-item-1 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 0 10px;

      & .pcars-payment-details-item-1-1 {
        color: $color-blue;
        font-size: 18px;
        font-weight: normal;
      }

      & .pcars-payment-details-item-1-2 {
        color: $color-blue;
        font-size: 18px;
        font-weight: 500;
        text-align: right;
      }
    }

    & .pcars-payment-details-item-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;

      & .pcars-payment-details-item-2-1 {
        color: $color-lightblue;
        font-size: 18px;
        font-weight: 600;
      }

      & .pcars-payment-details-item-2-2 {
        color: $color-lightblue;
        font-size: 18px;
        font-weight: 600;
        text-align: right;
      }
    }
  }
}

.pcars-payment-refund {
  text-align: right;
  margin: 0 0 25px;

  & p {
    color: $color-blue;
    font-size: 12px;
    font-weight: normal;
  }
}

.pcars-payment-checkboxes {
  margin: 0 auto 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.pcars-checkbox-item {
  align-items: center;

  & input {
    color: $color-blue !important;
    margin: 0 10px 0 0;
    cursor: pointer;
    border: 1px solid #182b89 !important;
    box-shadow: none !important;
  }
  & .pcars-checkbox-label {
    color: #000 !important;
    font-size: 12px;

    & span {
      color: $color-blue !important;
      cursor: pointer;
      font-weight: 600;
      text-decoration: underline;
      &:hover {
        text-decoration: initial;
      }
    }
  }
}

.pcars-payment-btn {
  color: #fff;
  background-color: $color-lightblue;
  width: 100%;
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  outline: 0;
  border: none;
  border-radius: 15px;
  transition: $transition-2s;
  &:hover {
    background-color: #64b6e3;
  }

  &.disabled {
    background-color: #b5b5b5;
  }
}

// modal
.checkbox-modal {
  backdrop-filter: blur(10px);
  z-index: 2000;

  .modal-dialog {
    max-width: 800px;
  }

  & .modal-content {
    border-radius: 20px;
    border: none;
    box-shadow: none;
  }
}
.checkbox-modal-header {
  // border: none;

  & .modal-title {
    color: $color-blue;
    font-size: 20px;
    font-weight: bold;

    & p {
      color: #aeaeae;
      font-size: 12px;
      font-weight: normal;
    }
  }
}
.checkbox-modal-body {
  max-height: 400px;
  overflow-y: scroll;
  padding: 16px 16px 6px;
  & h4 {
    color: $color-blue;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  & p {
    color: $color-blue;
    font-size: 14px;
    margin-bottom: 20px;
  }
  & ul {
    color: $color-blue;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 30px;
  }

  & .termscondition-content-1 {
    margin-bottom: 30px;
    & p {
      color: $color-blue;
      font-size: 14px;
      margin-bottom: 10px;
    }
    & ul {
      margin-top: -10px;
    }
  }
}
.checkbox-modal-btn {
  color: #fff;
  background-color: $color-lightblue;
  padding: 8px 20px;
  font-size: 12px;
  border: none;
  border-radius: 10px;
}

@media screen and (min-width: 1901px) {
}

@media screen and (max-width: 1200px) {
  .pcars-price-summary .pcars-summary-item-1 .pcars-summary-item-1-1,
  .pcars-payment-method > h5,
  .pcars-payment-details > h5 {
    font-size: 18px;
  }
  .pcars-price-summary .pcars-summary-item-1 .pcars-summary-item-1-2 {
    font-size: 30px;
  }

  .pcars-price-summary .pcars-summary-item-2 > h5 {
    font-size: 18px;
  }
  .pcars-price-summary .pcars-summary-item-2 .pcars-summary-item-2-wrap > p {
    font-size: 16px;
  }

  .pcars-price-payment .pcars-pricing-item-1 > h6,
  .pcars-price-payment .pcars-pricing-item-2 > h6 {
    font-size: 18px;
  }
  .pcars-price-payment .pcars-pricing-item-1 > p,
  .pcars-price-payment .pcars-pricing-item-2 > p {
    font-size: 26px;
  }

  .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-1
    .pcars-payment-details-item-1-1,
  .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-2
    .pcars-payment-details-item-2-1 {
    font-size: 16px;
  }

  .pcars-payment-btn {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .pcars-container {
    width: 90%;
  }

  .pcars-img-holder {
    height: 300px;
  }

  .pcars-price-summary .pcars-summary-item-1 {
    & .pcars-summary-item-1-1 {
      font-size: 18px;
    }
  }

  .pcars-p-method-container {
    gap: 20px;
  }
  .pcars-p-method-1 button .paymethod-var-item-1,
  .pcars-p-method-2 button .paymethod-var-item-1 {
    gap: 20px;
  }

  .pcars-price-payment .pcars-pricing-item-1 {
    grid-template-columns: 90px 1fr 1fr;

    & > h6 {
      font-size: 16px;
    }
    & > p {
      font-size: 22px;
    }
  }

  .pcars-price-payment .pcars-pricing-item-2 {
    grid-template-columns: 90px 1fr 1fr;

    & > h6 {
      font-size: 16px;
    }
    & > p {
      font-size: 22px;
    }
  }

  .pcars-payment-method {
    & > h5 {
      font-size: 18px;
    }
  }

  .pcars-payment-details {
    & > h5 {
      font-size: 18px;
    }
  }
  .pcars-pricing-item-1-3 {
    width: 170px !important;
  }
}

@media screen and (max-width: 576px) {
  .pcars-container {
    margin-bottom: 60px;
  }

  .pcars-details-header {
    & > p {
      font-size: 16px;
    }
    & > h3 {
      font-size: 20px;
    }
    & > .cars-card-item-country {
      font-size: 14px;
      & > .cars-card-item-country-icon {
        font-size: 20px;
      }
    }
  }

  .pcars-img-holder {
    width: 100%;
    height: auto;

    & > img {
      width: 100%;
      height: auto;
    }
  }

  .pcars-item-div {
    margin: 20px 0;
  }

  .pcars-price-summary .pcars-summary-item-1 {
    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 2fr 3fr;
    margin: 0 0 20px;

    & .pcars-summary-item-1-1 {
      font-size: 16px;
    }
    & .pcars-summary-item-1-2 {
      font-size: 20px;
      line-height: 0.8;
      & > span {
        font-size: 10px;
      }
    }
  }

  .pcars-price-summary .pcars-summary-item-2 {
    padding: 15px;

    & > h5 {
      font-size: 16px;
      font-weight: 600;
      margin: 5px 0 10px;
    }

    & .pcars-summary-item-2-wrap {
      grid-template-columns: 2fr 1fr;
      margin: 10px 0 5px;

      & > p {
        font-size: 14px;
      }
    }
  }

  .pcars-price-summary .pcars-summary-item-3 {
    width: 100%;
    margin: 0 0 30px;

    & p {
      font-size: 10px;
    }
  }

  .pcars-price-payment {
    padding: 20px 15px 15px;

    & .pcars-pricing-item-1 {
      grid-template-columns: initial;
      grid-auto-flow: row; /* it's the default value (can be omitted) */
      grid-gap: 5px 0;
      margin-bottom: 20px;

      & div:first-child {
        width: 85px;
        grid-area: span 2 / span 2;
        font-size: 10px;
      }

      & .pcars-pricing-item-1-2 {
        & > h6 {
          font-size: 14px;
        }
        & > p {
          font-size: 10px;
          padding-right: 10px;
        }
      }

      & .pcars-pricing-item-1-3 {
        width: fit-content !important;
        font-size: 14px;
        padding: 7px 15px;
        display: flex;
        align-items: center;
        & input {
          width: 90px;
          font-size: 14px;
        }
        & input::-webkit-input-placeholder {
          /* Edge */
          font-size: 14px;
        }
        & input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          font-size: 14px;
        }
        & input::placeholder {
          font-size: 14px;
        }
      }
    }

    & .pcars-pricing-item-2 {
      grid-template-columns: initial;
      grid-auto-flow: row;
      grid-gap: 5px 0;

      & div:first-child {
        width: 85px;
        grid-area: span 2 / span 2;
        font-size: 10px;
      }

      & > h6 {
        font-size: 14px;
      }
      & > p {
        font-size: 18px;
      }
    }
  }

  .pcars-payment-method {
    & > h5 {
      font-size: 16px;
    }
  }

  .pcars-p-method-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .pcars-p-method-1 button .paymethod-var-item-1,
  .pcars-p-method-2 button .paymethod-var-item-1 {
    gap: 20px;
  }

  .pcars-payment-details {
    margin: 0 0 20px;

    & > h5 {
      font-size: 16px;
    }

    & .pcars-payment-details-item {
      padding: 20px 15px;
    }
  }
  .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-1
    .pcars-payment-details-item-1-1,
  .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-2
    .pcars-payment-details-item-2-1 {
    font-size: 14px;
  }
  .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-1
    .pcars-payment-details-item-1-2,
  .pcars-payment-details
    .pcars-payment-details-item
    .pcars-payment-details-item-2
    .pcars-payment-details-item-2-2 {
    font-size: 16px;
  }

  .pcars-payment-refund p {
    font-size: 10px;
  }

  .pcars-payment-checkboxes {
    margin: 0 auto 30px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .pcars-checkbox-item .pcars-checkbox-label {
    font-size: 10px;
  }
  .checkbox-modal-header .modal-title {
    font-size: 16px;
  }
  .checkbox-modal-body p,
  .checkbox-modal-body ul {
    font-size: 12px;
  }
}
