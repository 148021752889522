$color-blue: #182b89;
$color-lightblue: #3ea6df;

$transition-2s: all 0.2s ease-in-out;

.wrap-prof-hero {
  width: 100%;
  height: 100vh;
  margin: -50px auto 0;
  // background-image: linear-gradient(to top, rgba(24, 43, 136, 0.4),rgba(24, 43, 136, 0.4), rgba(24, 43, 136, 1)),
  //     url('../../images/BG-41.png');
  background-image: linear-gradient(
      to top,
      rgba(24, 43, 136, 1),
      rgba(24, 43, 136, 0.8),
      rgba(24, 43, 136, 0.8)
    ),
    url("../../images/BG-15.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  // align-items: center;
  justify-content: center;
}

.prof-content-1 {
  width: 80%;
  max-width: 1600px;
  margin: 100px auto 0;
  & h1 {
    color: #fff;
    font-size: 24px;
  }
}

.prof-cont-item-1 {
  width: 450px;
  margin: 50px auto 0;
  padding: 60px 30px 50px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  background-color: rgba(238, 238, 238, 0.15);

  & > div {
    margin-bottom: 20px;
  }

  & h2 {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  & .profile-username,
  & .profile-email {
    & input {
      width: 100%;
      color: $color-blue;
      background-color: #fff;
      font-size: 14px;
      font-weight: normal;
      padding: 8px 20px;
      border: none;
      border-radius: 10px;
      outline: none;
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
      -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
      -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    }
  }

  & .profile-phone {
    & div {
      color: $color-blue;
      background-color: #fff;
      display: flex;
      align-items: center;
      padding: 8px 18px;
      font-size: 14px;
      border: none;
      border-radius: 10px;
      box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
      -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
      -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
      & span.hidden {
        display: none;
      }
    }
    & input {
      color: $color-blue;
      width: 100%;
      margin-left: 5px;
      font-size: 14px;
      outline: 0;
      border: none;
    }
  }

  & .profile-edit-btn {
    text-align: center;
    & button {
      color: #fff;
      background-color: $color-lightblue;
      width: 100%;
      padding: 10px;
      margin: 10px 0 0;
      font-size: 14px;
      font-weight: bold;
      border: none;
      border-radius: 10px;
      outline: none;
      transition: $transition-2s;
      &:hover {
        background-color: #64b6e3;
      }
      &:nth-child(2) {
        background-color: #060c84;
        &:hover {
          background-color: $color-blue;
        }
      }
      &:nth-child(3) {
        background-color: $color-lightblue;
        &:hover {
          background-color: #64b6e3;
        }
      }
    }
    & .p-chgps-btn {
      background-color: #060c84;
      &:hover {
        background-color: $color-blue;
      }
    }
    & .hidden {
      display: none;
    }
  }
}

.chg-pass-modal {
  & .login-alert-text {
    font-size: 12px !important;
  }
}

// v2 layout
.v2-prof-container {
  width: 80%;
  max-width: 1660px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  align-items: center;
}

.v2-prof-content-1 {
  text-align: center;
  & img {
    width: 70%;
    max-width: 450px;
  }
}

.v2-prof-content-2 {
  width: 100%;
  height: auto;
  padding: 60px 30px;
  margin: 0;
}

.v2-prof-content-2-form {
  width: 100%;
  border-radius: 20px;
  padding: 40px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(68, 81, 169, 0.35);

  & h1 {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}
.v2-prof-form-item {
  color: #fff;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;

  & label {
    font-size: 14px;
    font-weight: 500;
  }

  & input {
    width: 100%;
    color: $color-blue;
    background-color: #fff;
    font-size: 14px;
    font-weight: normal;
    padding: 8px 20px;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
  }
}
.v2-prof-form-btns {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  & button {
    color: #fff;
    width: 100%;
    padding: 12px;
    margin: 10px 0 0;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    outline: none;
    transition: $transition-2s;
  }

  & .edit_profbtn,
  & .saveChg_profbtn {
    background-color: #060c84;
    &:hover {
      background-color: $color-blue;
    }
  }
  & .chgPs_profbtn {
    background-color: $color-lightblue;
    &:hover {
      background-color: #64b6e3;
    }
  }
  & .cancel_profbtn {
    background-color: #ffa600;
    &:hover {
      background-color: #d78c00;
    }
  }
}
.v1-prof-form-btns {
  margin-top: 40px;
  display: flex;

  & button {
    color: #fff;
    width: 100%;
    padding: 12px;
    margin: 10px 0 0;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    outline: none;
    transition: $transition-2s;
  }

  & .edit_profbtn,
  & .saveChg_profbtn {
    background-color: #060c84;
    &:hover {
      background-color: $color-blue;
    }
  }
  & .chgPs_profbtn {
    background-color: $color-lightblue;
    &:hover {
      background-color: #64b6e3;
    }
  }
  & .cancel_profbtn {
    background-color: #ffa600;
    &:hover {
      background-color: #d78c00;
    }
  }
}

@media screen and (min-width: 1901px) {
  .prof-cont-item-1 {
    width: 450px;
    margin: 70px auto 0;
  }
}

@media screen and (max-width: 1200px) {
  .v2-prof-container {
    width: 90%;
  }
  .v2-prof-content-2 {
    padding: 30px 0;
  }
}

@media screen and (max-width: 1024px) {
  .wrap-prof-hero {
    height: fit-content;
    padding: 60px 0;
  }
}

@media screen and (max-width: 720px) {
  .wrap-prof-hero {
    margin: 0 auto;
  }
}

@media screen and (max-width: 576px) {
  .wrap-prof-hero {
    height: fit-content;
    padding: 40px 0 0;
  }

  .prof-content-1 {
    width: 90%;

    & h1 {
      font-size: 20px;
    }
  }

  .prof-cont-item-1 {
    width: 100%;
    margin: 30px auto 0;
    padding: 40px 30px;
  }

  .v2-prof-container {
    grid-template-columns: 1fr;
  }
  .v2-prof-content-2-form {
    padding: 30px;
  }
  .v2-prof-form-btns {
    gap: 20px;

    & button {
      font-size: 12px;
    }
  }
}
