$color-blue: #182b89;
$color-lightblue: #3ea6df;

$transition-2s: all 0.2s ease-in-out;

.wrap-policies {
  width: 100%;
  height: auto;
  margin: -80px auto 0;
  padding: 0 0 90px;
  // background-image: linear-gradient(to top, rgba(24, 43, 136, 0.4),rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url('../../images/BG-15.png');
  // background-repeat: no-repeat;
  // background-position: top center;
  // background-size: cover;

  .policies-block {
    display: none;
    // width: 100%;
    // height: 80px;
    // background: $color-blue;
  }
}

.policies-header {
  background: $color-blue;
  height: 200px;
  margin: 0 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  & h1 {
    color: #fff;
    width: 75%;
    max-width: 1600px;
    margin-top: 60px;
    padding: 0 0 0 15px;
    font-size: 24px;
    font-weight: bold;
    border-left: 15px solid #fff;
  }
}

.policies-container-1 {
  width: 75%;
  max-width: 1600px;
  margin: 0 auto 40px;

  & p {
    color: $color-blue;
    font-size: 14px;
    font-weight: normal;
    margin: 0 auto 20px;
    line-height: 1.7;

    & ul {
      margin-left: 30px;
    }
  }
}

.policies-container-2 {
  width: 75%;
  max-width: 1600px;
  margin: 0 auto 40px;

  & h2 {
    color: $color-blue;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  & p {
    color: $color-blue;
    font-size: 14px;
    font-weight: normal;
    margin: 0 auto 10px;
    line-height: 1.7;

    & ul {
      margin-left: 30px;
    }
  }
}

@media screen and (min-width: 1901px) {
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
  .policies-header {
    width: 100%;

    & h1 {
      width: 85%;
    }
  }
  .policies-container-1 {
    width: 85%;
  }
  .policies-container-2 {
    width: 85%;
    & p {
      & ul {
        margin-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .wrap-policies {
    padding: 0 0 60px;
  }

  .policies-header {
    width: 100%;
    height: 180px;
    margin: 0 auto 30px;

    & h1 {
      width: 90%;
      margin-top: 80px;
      font-size: 18px;
    }
  }

  .policies-container-1 {
    width: 90%;
    margin-bottom: 40px;

    & p {
      font-size: 12px;

      & ul {
        margin-left: 20px;
      }
    }
  }

  .policies-container-2 {
    width: 90%;

    & p {
      font-size: 12px;

      & ul {
        margin-left: 20px;
      }
    }
  }
}
