$color-blue: #182b89;
$color-lightblue: #3ea6df;

$transition-2s: all 0.2s ease-in-out;

// hero
.wrap-searRe-hero {
  width: 100%;
  height: 100vh;
  margin: -80px auto 0;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(24, 43, 136)
    ),
    url("../../images/BG-38.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.searRe-hero-container {
  width: 50%;
  background-color: rgba(238, 238, 238, 0.15);
  border-radius: 0 500px 500px 0;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: grid;
  grid-template-columns: 1fr;
  padding: 120px 0;
  align-items: center;
  justify-content: center;

  & > h1 {
    width: 70%;
    margin: 0 auto 20px;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    display: grid;
    grid-template-columns: 1fr;
    & > span {
      font-size: 50px;
    }
  }

  & > p {
    width: 70%;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
  }
}

// content
.wrap-searRe {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.searRe-header {
  width: 80%;
  max-width: 1600px;
  margin: 0 auto 60px;
  padding: 0 0 0 20px;
  border-left: 20px solid $color-blue;

  & .searRe-breadcrumbs {
    display: flex;

    & p {
      color: #aeaeae;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0.5px;
      text-decoration: none;
      text-transform: capitalize;
      transition: $transition-2s;
    }

    & .searRe-breadcrumbs-divider {
      color: #aeaeae;
      margin: 0 10px;
      font-size: 22px;
    }

    & h2 {
      color: $color-blue;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0.5px;
      margin: 0;
      line-height: initial;
      text-transform: capitalize;
    }
  }
}

.searRe-content-1 {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto 30px;

  & .ant-empty {
    margin: 60px 0 90px;
    & span {
      color: #abb8c1;
    }
  }
}

.searRe-footnote {
  color: $color-blue;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  text-align: right;
  margin-bottom: 10px;
}

// accordion
.searRe-accord-1 {
  background-color: transparent;
  border: none;
  border-radius: 0;

  & .accordion-item {
    margin: 0 auto 50px;
    border-radius: 15px;
    border: none;
    box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5) !important;
    -moz-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
    -webkit-box-shadow: 0 0 8px 0 rgba(62, 166, 223, 0.5);
  }

  & .accordion-button {
    background: transparent;
    width: 100%;
    // display: grid;
    // grid-template-columns: 1fr;
    // gap: 10px;

    &:focus {
      box-shadow: none;
    }
    &::after {
      // display: none;
      width: 15px;
      height: 15px;
      color: $color-blue;
      margin: 0 0 0 20px;
      background: url("../../images/chevron-down-01.png");
      background-size: 100%;
    }
    &:not(.collapsed)::after {
      background: url("../../images/chevron-down-02.png");
      background-size: 100%;
    }
  }

  & .accordion-button:not(.collapsed) {
    box-shadow: none;
  }
}

.searRe-accord-header {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & h3 {
    color: $color-blue;
    font-size: 18px;
    font-weight: 600;
  }
  // add status indicator. yellow for pending, green for approved after the chevron
  & .searRe-status-indicator {
    padding: 5px 20px;
    margin: 15px 20px;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    background-color: #f5a623;

    @media (max-width: 767px) {
      margin: 0 0 20px 20px;
      font-size: 12px;
    }
  }
  
  & .searRe-status-indicator-2 {
    background-color: #00b894;
  }
}
.searRe-accord-header-2 {
  width: 100%;

  & .accordion-button {
    padding: 0 20px 16px;
    &::after {
      display: none;
    }
  }
}

.searRe-accord-header-content {
  width: 100%;
  padding: 0;
  margin: 0 auto;

  & .searRe-item-title-1 {
    color: $color-blue;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  & .searRe-item-desc-1 {
    color: $color-lightblue;
    font-size: 18px;
    font-weight: bold;
  }
}
.searRe-accord-header-content-1 {
  margin: 0 0 20px;
}
.searRe-accord-header-content-2 {
  margin: 0;
}

.searRe-accord-body {
  border-top: 2px solid #d9d9d9;

  & .searRe-item-title-1 {
    color: $color-blue;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  & .searRe-item-desc-1 {
    color: $color-lightblue;
    font-size: 18px;
    font-weight: bold;
  }
}
.searRe-accord-body-content-1 {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 20px;
  margin: 0 auto 20px;
}
.searRe-accord-body-content-2 {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 20px;
}

.searRe-antd-pagination {
  margin: 0 auto;
  text-align: center;
  justify-content: center;

  & .ant-pagination-item-link {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  & .anticon {
    vertical-align: 0 !important;
  }

  & .ant-pagination-item-active {
    border: 1px solid $color-lightblue;
    color: $color-blue;
  }
}

.searRe-link-button {
  border: 1px solid #182b89;
  border-radius: 15px;
  text-decoration: none;
  width: fit-content;
  padding: 8px 20px;
  background: #fff;
  color: #182b89;
  font-weight: bold;
  margin: 20px 0 0;

  & span {
    font-size: 14px;
    margin: 0 10px 0 0;
  }
}

@media screen and (min-width: 1901px) {
  .searRe-hero-container {
    padding: 180px 0;
  }

  .searRe-header {
    width: 80%;
    max-width: 1600px;
    margin: 0 auto 30px;
  }
}

@media screen and (max-width: 1200px) {
  .wrap-searRe-hero {
    height: 800px;
  }

  .searRe-header {
    width: 90%;
    margin: 0 auto 30px;

    & .searRe-breadcrumbs {
      flex-wrap: wrap;

      & p {
        font-size: 18px;
      }
      & h2 {
        width: fit-content;
        font-size: 18px;
      }
      & .myord-breadcrumbs-divider {
        font-size: 18px;
      }
    }
  }

  .searRe-content-1 {
    width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .wrap-searRe-hero {
    height: 700px;
  }
  .searRe-hero-container {
    width: 65%;
    padding: 90px 0;

    & > h1 {
      font-size: 32px;
      display: initial;

      & > span {
        font-size: 42px;
      }
    }
    & > p {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 576px) {
  .searRe-hero-container {
    width: 80%;
    padding: 50px 0;

    & > h1 {
      font-size: 24px;

      & > span {
        font-size: 30px;
      }
    }
    & > p {
      font-size: 16px;
    }
  }

  .searRe-header {
    width: 90%;
    margin: 0 auto 30px;

    & .searRe-breadcrumbs {
      font-size: 14px;
      flex-wrap: wrap;
    }
  }

  .searRe-content-1 {
    width: 90%;
  }
  .searRe-footnote {
    font-size: 11px;
  }

  .searRe-accord-1 {
    & .accordion-button::after {
      margin: 0 0 0 auto;
    }

    & .accordion-item {
      margin: 0 auto 30px;
    }
  }
  .searRe-accord-header h3 {
    font-size: 14px;
  }
  .searRe-accord-header-content {
    & .searRe-item-title-1 {
      font-size: 12px;
      margin-bottom: 6px;
    }
    & .searRe-item-desc-1 {
      font-size: 12px;
    }
  }
  .searRe-accord-body-content-1 {
    grid-template-columns: 1fr;
  }
  .searRe-accord-body-content-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .searRe-accord-body {
    & .searRe-item-title-1 {
      font-size: 12px;
      margin-bottom: 6px;
    }
    & .searRe-item-desc-1 {
      font-size: 12px;
    }
  }
  .searRe-link-button {
    padding: 3px 20px 4px;
    border-radius: 10px;
    & span {
      font-size: 12px;
      margin: 0 10px 0 0;
    }
  }
}
