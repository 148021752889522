$color-blue: #182b89;

$transition-2s: all 0.2s ease-in-out;

.wrap-footer {
  width: 100%;
  height: auto;
  padding: 60px 0;
  margin: 0 auto;
  background-color: #182b89;
}

.footer-container-1 {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.footer-content-1 {
  padding: 0;

  & > img {
    width: 250px;
    margin: 0 0 30px;
  }

  & > p {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}

.footer-content-2 {
  width: fit-content;
  margin: 0 auto;

  & > h3 {
    color: #fff;
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: bold;
  }

  & > .footer-cont-2-links {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;

    & a {
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      text-decoration: none;
      &.active {
        font-weight: bold;
      }

      & > .f-cont-2-link-icon {
        margin-right: 10px;
      }
    }
  }
}

.footer-content-3 {
  & > h3 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 10px;
  }

  & > address {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 30px;
  }

  & > .f-cont-3-item-1 {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 20px;

    & > a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      & > .f-cont-3-icon {
        color: #fff;
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }

  & > .f-cont-3-item-2 {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    & img {
      width: 100%;
      cursor: pointer;
    }
  }
}

.footer-container-2 {
  width: 80%;
  max-width: 1600px;
  height: auto;
  margin: 0 auto;
  text-align: center;
  border-top: 1px solid #fff;

  & > p {
    color: #fff;
    margin: 30px auto 0;
    font-size: 12px;
    font-weight: normal;
  }
}

@media screen and (min-width: 1901px) {
  .footer-content-3 > .f-cont-3-item-2 {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    & > img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .footer-container-1 {
    width: 90%;
  }

  .footer-content-1 {
    & > img {
      width: 200px;
    }
  }

  .footer-content-2 {
    & > h3 {
      font-size: 18px;
    }
  }

  .footer-content-3 {
    & > h3 {
      font-size: 18px;
    }

    & > address {
      margin-bottom: 30px;
    }
  }

  .footer-content-3 > .f-cont-3-item-2 {
    width: 100%;

    & > img {
      width: 100%;
    }
  }

  .footer-container-2 {
    width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .footer-content-1 {
    & > img {
      width: 180px;
    }

    & > p {
      font-size: 12px;
    }
  }

  .footer-content-2 {
    & > h3 {
      font-size: 16px;
    }
  }

  .footer-content-3 {
    & > h3 {
      font-size: 16px;
    }

    & > address {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 576px) {
  .footer-container-1 {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .footer-content-1 {
    text-align: center;

    & > img {
      margin: 0 auto 20px;
    }

    & > p {
      text-align: left;
    }
  }

  .footer-content-2 {
    width: 100%;
    margin: 0;

    & > .footer-cont-2-links {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
  }
}
