.backtotop_icon {
  /* margin-top: -30px; */
  width: 60px;
  height: 60px;
  padding: 12px;
}

#message_modal {
  width: 750px;
  max-width: 750px;
}

.whatsapp-modal {
  & .modal-content {
    outline: 0;
    border: none;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  & .modal-header {
    color: #000;
    border: none;

    & > .modal-title {
      font-size: 20px;
    }
    & > img {
      width: 160px;
      height: auto;
      margin: 20px 0 0;
    }
  }

  & .modal-body {
    padding: 20px 40px;
  }

  & textarea {
    padding: 10px;
    outline: 0;
    border: 2px solid #ececec;
    border-radius: 10px;
    background-color: #ececec;
    // &:focus {
    //     border: 2px solid rgb(90, 90, 90);
    // }
  }

  & .modal-footer {
    border: none;
  }
}

@media screen and (max-width: 1200px) {
  #message_modal {
    width: 90%;
    max-width: 90%;
  }
}

@media screen and (max-width: 576px) {
  .whatsapp-modal .modal-body {
    padding: 20px;
  }
}
